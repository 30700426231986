import React, { useEffect, useState } from "react";
import "./Projects.scss";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../i18n";
import lineImg from "../../Assets/Images/projects/lineImg.svg";
import projectImage1 from "../../Assets/Images/projects/project-image-1.webp";
import projectImage2 from "../../Assets/Images/projects/project-image-2.webp";
import projectImage3 from "../../Assets/Images/projects/project-image-3.webp";
import projectImage4 from "../../Assets/Images/projects/project-image-4.webp";
import projectImage5 from "../../Assets/Images/projects/project-image-5.webp";
import projectImage6 from "../../Assets/Images/projects/project-image-6.webp";
import projectImage7 from "../../Assets/Images/projects/project-image-7.webp";
import projectImage8 from "../../Assets/Images/projects/project-image-8.webp";
import projectImage9 from "../../Assets/Images/projects/project-image-9.webp";
import projectImage11 from "../../Assets/Images/projects/project-image-11.webp";
import projectImage12 from "../../Assets/Images/projects/project-image-12.webp";
import projectImage13 from "../../Assets/Images/projects/project-image-13.webp";
import projectImage14 from "../../Assets/Images/projects/project-image-14.webp";
import projectImage15 from "../../Assets/Images/projects/project-image-15.webp";
import projectImage16 from "../../Assets/Images/projects/project-image-16.webp";
import founder2 from "../../Assets/Images/projects/line2.svg";
import farm9 from "../../Assets/Images/projects/k9.webp";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Components/Header/Header";
import { Helmet } from "react-helmet";
export default function Projects() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageData = useSelector((state) => state.language);
  let newObject = JSON.parse(window.localStorage.getItem("languageData"));
  useEffect(() => {
    if (newObject && newObject != null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);
  const translatedText = t("projects.projectsEvent");
  const mainText = t("projects.projectEvents");
  // ("हर क्षेत्र में हम भारती भारतवासिंयों के लिए निरंतर प्रयासरत");
  return (
    <div id="projects-container">
      <Helmet>
        <link rel="canonical" href="https://www.drswapnaverma.com/projects" />
        <title>Dr. Swapna Verma: Empowering Health & Agriculture</title>
        <meta
          name="description"
          content="Dr. Swapna Verma's initiatives - Clinic on Wheels, Disease-Free India, Digital Agriculture, Geranium cultivation & processing to empower communities & farmers."
        />
        <meta
          name="keywords"
          content="Clean Clinic on Wheels, Disease Free India,Geranium Farming, Empowering Farmers, Digital Agriculture, Healthcare Access, Women Empowerment, Farmer Empowerment, Rural Development"
        />
      </Helmet>
      <br />
      <br />
      <Header title={translatedText} subTitle={mainText} />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div class="sec-22 mt-0 ">
        <div class="container shadow-none">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-5">
              {/* <img src={founder1} class="img-fluid " style={{ width: "198px" }} /> */}
              <div class="media card-a p-2 mt-3">
                <img src={farm9} class="img-fluid dot-img" id="string-icon" />
                <div class="media-body">
                  <h5 class="mt-1 text-center px-4" id="text-project-name">
                    {t("projects.new_shadow")}
                  </h5>
                  <span className="cpan-box-text">
                    {t("projects.new_shadow1")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-lg-3"></div>
            <div class="col-lg-5" id="align-line">
              <img src={founder2} class="img-fluid mx-auto  mt-3" />
            </div>
          </div>
        </div>
      </div>
      <div className="background-bg">
        <div className="container project-screen">
          <Container>
            <br />
            <br />
            <br />
            <Row style={{ gap: "27px" }}>
              <Col xs={12} md={7}>
                <h2 className="clinic-text">{t("projects.projectsName1")}</h2>
                <br />
                <div className="project-title-name" id="bus-text">
                  <span className="span-fount pan-fountsection2-des-text">
                    {t("projects.projectsName2")}
                  </span>
                  <br />
                  <br />
                  <span className="span-fount section2-des-text">
                    {/* {t("projects.projectsName3")} */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("projects.projectsName3"),
                      }}
                    ></div>
                    {/* <span className="hailait-text"> {t("projects.projectsName4")}</span> */}
                  </span>
                </div>
              </Col>
              <Col xs={12} md={4} className="" style={{ marginTop: "27px" }}>
                <img
                  src={projectImage1}
                  alt="Left"
                  className="img-fluid project-images"
                />
                <br />
                <br />
                <img
                  src={projectImage3}
                  alt="Right"
                  className="img-fluid project-images"
                />
                <br />
                <br />
                <img
                  src={projectImage4}
                  alt="Right"
                  className="img-fluid project-images"
                />
              </Col>
            </Row>

            <br />
            <br />
            <br />

            <Row style={{ gap: "27px" }}>
              <Col xs={12} md={7}>
                <h2 className="clinic-text">{t("projects.projectsName7")}</h2>
                <br />
                <div className="project-title-name" id="bus-text">
                  <span className="span-fount section2-des-text">
                    {t("projects.industry_description_4")}
                  </span>

                  <span className="span-fount section2-des-text">
                    {t("projects.industry_description_5")}
                  </span>
                  <span className="span-fount section2-des-text">
                    {t("projects.industry_description_6")}
                    <br />
                    {t("projects.industry_description_9")}
                    <br />
                  </span>
                </div>
              </Col>
              <Col xs={12} md={4} className="" style={{ marginTop: "4px" }}>
                <img
                  src={projectImage5}
                  alt="Left"
                  className=" mediacle-images"
                />
                <br />
                <br />
                <img
                  src={projectImage6}
                  alt="Right"
                  className=" mediacle-images"
                />
                <br />
                <br />
                <img
                  src={projectImage7}
                  alt="Right"
                  className=" mediacle-images"
                />
                <br />
                <br />
                <img
                  src={projectImage8}
                  alt="Right"
                  className=" mediacle-images"
                />
              </Col>
            </Row>
            <br />

            <Row style={{ gap: "27px" }}>
              <div className="farming-section">
                <h2 className="d-flex justify-content-center">
                  {" "}
                  {t("projects.farming")}{" "}
                </h2>
                <div className="d-flex justify-content-center">
                  <img src={founder2} />
                </div>
              </div>
              <Col xs={12} md={7}>
                <h2 className="clinic-text">{t("projects.farming1")}</h2>
                <div className="project-title-name" id="bus-text">
                  <span className="span-fount section2-des-text">
                    {t("projects.farming_description_1")}
                  </span>
                  <br />
                </div>
                <br />
                <h2 className="clinic-text">{t("projects.industry")}</h2>
                <div className="project-title-name" id="bus-text">
                  <span className="span-fount section2-des-text">
                    {t("projects.industry_description_1")}
                  </span>
                </div>

                <br />
                <h2 className="clinic-text">
                  {t("projects.industry_description_7")}
                </h2>
                <div className="project-title-name" id="bus-text">
                  <span className="span-fount section2-des-text">
                    {t("projects.industry_description_3")}
                  </span>
                  <br />
                  <br />
                  <span className="span-fount section2-des-text">
                    {t("projects.industry_description_11")}
                  </span>
                </div>
              </Col>
              <Col xs={12} md={4} className="" style={{ marginTop: "4px" }}>
                <img src={projectImage9} alt="Left" className=" img-fluid" />
                <br />
                <br />
                <img src={projectImage11} alt="Right" className=" img-fluid" />
                <br />
                <br />
                <img src={projectImage12} alt="Right" className=" img-fluid" />
              </Col>
            </Row>
            <br />
            <br />

            <Row style={{ gap: "27px" }}>
              <div className="farming-section">
                <h2 className="d-flex justify-content-center">
                  {" "}
                  {t("projects.farming_new")}{" "}
                </h2>
                <div className="d-flex justify-content-center">
                  <img src={founder2} />
                </div>
              </div>
              <Col xs={12} md={7}>
                <h2 className="clinic-text">{t("projects.farming1_new")}</h2>
                <div className="project-title-name" id="bus-text">
                  <span className="span-fount section2-des-text">
                    {t("projects.farming_description_1_new")}
                  </span>
                  <br />
                </div>
                <br />
                <h2 className="clinic-text">{t("projects.farming_new1")}</h2>
                <div className="project-title-name" id="bus-text">
                  <span className="span-fount section2-des-text">
                    {t("projects.farming_description_1_new1")}
                  </span>
                </div>

                <br />
                <h2 className="clinic-text">{t("projects.farming_new2")}</h2>
                <div className="project-title-name" id="bus-text">
                  <span className="span-fount section2-des-text">
                    {t("projects.farming_description_1_new2")}
                  </span>
                </div>
                <br />
                <h2 className="clinic-text">{t("projects.farming_new3")}</h2>
                <div className="project-title-name" id="bus-text">
                  <span className="span-fount section2-des-text">
                    {t("projects.farming_description_1_new4")}
                  </span>
                </div>
              </Col>
              <Col xs={12} md={4} className="" style={{ marginTop: "4px" }}>
                <img src={projectImage13} alt="Left" className=" img-fluid" />
                <br />
                <br />
                <img src={projectImage14} alt="Right" className=" img-fluid" />
                <br />
                <br />
                <img src={projectImage15} alt="Right" className=" img-fluid" />
                <br />
                <br />
                <img src={projectImage16} alt="Right" className=" img-fluid" />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
