import React from "react";
import "./Initiatives.scss";
import Initiaimg1 from "../../Assets/Images/initiative/Initiatives-img1.webp";
import backIcon from "../../Assets/Images/initiative/back-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InitiativesHeader from "./InitiativesHeader";
import { Helmet } from "react-helmet";
import SubHeader from "../../Components/Header/SubHeader";
export default function Healthcare() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageData = useSelector((state) => state.language);
  let newObject = JSON.parse(window.localStorage.getItem("languageData"));
  useEffect(() => {
    if (newObject && newObject != null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);

  const translatedText = t("initiatives.initiatives_text");
  const backBtn = t("event.backButton");
  const maintaxt = t("initiatives.social_text1");

  const navigate = useNavigate();
  const navigatePage = () => {
    navigate("/initiatives");
  };
  return (
    <div className="content-initiative">
      <Helmet>
        <link rel="canonical" href="https://www.drswapnaverma.com/healthcare" />
        <title>Clinic on Wheels: Free Medical Care for Rural India</title>
        <meta
          name="description"
          content="Madhurima Sewa Sanskar provides free health checkups, medicines & treatments through 'Clinic on Wheels' in rural India. Fight for a Disease-free India!"
        />
        <meta
          name="keywords"
          content="Clinic on Wheels, Free Medical Camps, Mobile Medical Units, Disease free India Campaign, Rural Healthcare, Free Health Checkups, free medications"
        />
      </Helmet>
      <br />
      <br />
      <SubHeader title={translatedText} />
      <div>
        <InitiativesHeader backButton={backBtn} titleName={maintaxt} />
      </div>
      <br />
      <br />
      <div className="bg-all-Initiatives">
        <br />
        <br />
        <div className="container custom-container mt-4 text-center">
          <div className="row">
            <h3 className="main-title">{t("initiatives.healthText")}</h3>
            <br />
            <br />

            <div>
              <a
                href="https://drive.google.com/file/d/1NsWoYvWLZadpUBDmwmnHnudyL6dPMePN/view"
                target="_blank"
              >
                <img src={Initiaimg1} className="img-fluid  main-header-img" />
              </a>
            </div>
            <div className="justify-content mt-4">
              <span className="">{t("initiatives.healthText1")}</span>
            </div>
          </div>
          <div className="row">
            <div>
              <div className="justify-content">
                <span> {t("initiatives.healthText2")} </span>
                <br />
                <br />
                <span> {t("initiatives.healthText3")} </span>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}
