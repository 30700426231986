import React from "react";
import "./loaderScreen.scss";
import loaderIcon from "../../Assets/Images/loaderScreen/loader-icon.gif";

const Loader = () => {
    return (
        <div className="loader-container justify-content-center">
            <img src={loaderIcon} alt="Loading..." />
        </div>
    );
};

export default Loader;