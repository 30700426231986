import React from "react";
import "../Header/Header.scss";

const Header = ({ title, subTitle }) => (
  <>
    <div id="header_title">
      <div className="container">
        <h1 className="title-header">{title}</h1>
      </div>
      {subTitle && (
        <div className="sub-header-section1">
          <h1 className="sub-header">{subTitle}</h1>
        </div>
      )}
    </div>
  </>
);

export default Header;
