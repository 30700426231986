import React from "react";
import "./Initiatives.scss";
import Header from "../../Components/Header/Header";
import backIcon from "../../Assets/Images/initiative/back-icon.svg";
import Initiaimg2 from "../../Assets/Images/initiative/img-2.webp";
import agreimg from "../../Assets/Images/initiative/agre-img.webp";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InitiativesHeader from "./InitiativesHeader";
import { Helmet } from "react-helmet";
import SubHeader from "../../Components/Header/SubHeader";

export default function Agriculture() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageData = useSelector((state) => state.language);
  let newObject = JSON.parse(window.localStorage.getItem("languageData"));
  useEffect(() => {
    if (newObject && newObject != null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);

  const translatedText = t("initiatives.initiatives_text");
  const navigate = useNavigate();
  const navigatePage = () => {
    navigate("/initiatives");
  };
  const backBtn = t("event.backButton");
  const maintaxt = t("initiatives.social_text2");
  return (
    <div className="content-initiative">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.drswapnaverma.com/agriculture"
        />
        <title>Empower Farmers with FPOs & Digital Agriculture</title>
        <meta
          name="description"
          content="Farmer Producer Organizations (FPOs), Dr. Swapna Verma, alternative crops, precision agriculture, digital platforms, agribusiness, modern farming practices."
        />
        <meta
          name="keywords"
          content="Digital Agriculture for FPOs, Farmer Producer Organizations (FPOs), digital farming, agricultural information, modern farming practices, precision agriculture, Digital Agriculture, empower farmers, agricultural information"
        />
      </Helmet>
      <br />
      <br />
      <SubHeader title={translatedText} />
      <InitiativesHeader backButton={backBtn} titleName={maintaxt} />
      <div className="bg-all-Initiatives">
        <br />
        <br />
        <div className="container custom-container mt-4">
          <div className="row">
            <h3 className="main-title text-center">
              {t("initiatives.agreText1")}
            </h3>
            <div className="col-md-1 col-sm-12"></div>
            <div className="col-md-5 col-sm-12 mt-4">
              <div className="justify-content ">
                {t("initiatives.agreText2")}
              </div>
            </div>
            {/*/////////////////////// need to change img of img2  ///////////////////////////////////////////////// */}
            <div className="col-md-5 col-sm-12 mt-4">
              <div>
                <img src={Initiaimg2} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-1 col-sm-12"></div>
          </div>

          <div className="row">
            <div className="col-md-1 col-sm-12"></div>
            <div className="col-md-5 col-sm-12  ">
              <div className="justify-content">
                <span> {t("initiatives.agreText3")}</span>
                <br />
                <span> {t("initiatives.agreText4")}</span>
              </div>
            </div>
            <div className="col-md-5 col-sm-12 mt-4">
              <div>
                <a
                  href="https://drive.google.com/file/d/1NrjnS5wFBYv2dqdXpQV5lvADL7M5wzm8/view"
                  target="_blank"
                >
                  <img src={agreimg} className="img-fluid" />
                </a>
              </div>
            </div>
            <div className="col-md-1 col-sm-12"></div>
          </div>

          <div className="row">
            <div className="col-md-1 col-sm-12"></div>
            <div className="col-md-6 col-sm-12 mt-4">
              <div></div>
            </div>
            <div className="col-md-4 col-sm-12 mt-4"></div>
            <div className="col-md-1 col-sm-12"></div>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
