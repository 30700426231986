import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import About from "./Screens/About/About";
import Events from "./Screens/Events/Events";
import "bootstrap/dist/css/bootstrap.css";
import Projects from "./Screens/Projects/Projects";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Achievements from "./Screens/Achievements/Achievements";
// import Awards from "./Screens/Awards/Awards";
import MyVoice from "./Screens/MyVoice/MyVoice";
import Press from "./Screens/Press/Press";
import Sidebar from "./Components/Header/Sidebar";
import Home from "./Screens/Home/Home";
import Initiatives from "./Screens/Initiatives/Initiatives";
import MissionVision from "./Screens/MissionVision/MissionVision";
import Healthcare from "./Screens/Initiatives/Healthcare";
import Agriculture from "./Screens/Initiatives/Agriculture";
import Education from "./Screens/Initiatives/Education";
import HealthAwareness from "./Screens/Initiatives/HealthAwareness";
import WomenEmpowerment from "./Screens/Initiatives/WomenEmpowerment";
import DharmikKaryakram from "./Screens/dharmikKaryakram/dharmikKaryakram";
import MainScreen from "./Screens/MainScreen/MainScreen";
import DigitalPrint from "./Screens/DigitalPrint/DigitalPrint";
import RamRajya from "./Screens/Initiatives/RamRajya";
import Glimpse from "./Screens/Glimpse/Glimpse";

function App() {
  const [apiData, setApiData] = useState([]);

  return (
    <BrowserRouter>
      <div class="">
        <Sidebar />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/profile" element={<MainScreen />} />
          <Route exact path="/about-us" element={<About />} />
          <Route exact path="/media-gallery/events" element={<Events />} />
          <Route
            exact
            path="/media-gallery/religious-events"
            element={<DharmikKaryakram />}
          />
          <Route exact path="/press/news-clip" element={<Press />} />
          <Route exact path="/press/digital-print" element={<DigitalPrint />} />
          <Route exact path="/vision-mission" element={<MissionVision />} />
          <Route exact path="/projects" element={<Projects />} />
          <Route exact path="/media-gallery/my-voice" element={<MyVoice />} />
          <Route exact path="/media-gallery/a-glimpse" element={<Glimpse />} />
          <Route exact path="/achievements" element={<Achievements />} />
          <Route exact path="/healthcare" element={<Healthcare />} />
          <Route exact path="/agriculture" element={<Agriculture />} />
          <Route exact path="/education" element={<Education />} />
          <Route exact path="/health-awareness" element={<HealthAwareness />} />
          <Route exact path="/women" element={<WomenEmpowerment />} />
          <Route
            exact
            path="/establishment-of-ram-rajya"
            element={<RamRajya />}
          />
          <Route path="*" element={<Navigate to="/" />} />
          <Route exact path="/press" element={<Press />} />
          <Route exact path="/initiatives" element={<Initiatives />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
