import React from "react";
import PropTypes from "prop-types";
import "./Initiatives.scss";
import { useNavigate } from "react-router-dom";
import backIcon from "../../Assets/Images/events/back-icon.svg";

const InitiativesHeader = ({ backButton, titleName }) => {
  const navigate = useNavigate();

  const navigatePage = () => {
    navigate("/initiatives");
  };

  return (
    <>
      <div id="initiatives-header">
        <div className="container">
          <p className="back-btn" role="button" onClick={() => navigatePage()}>
            {backButton}
          </p>
          <img src={backIcon} className="back-btn-pahel " />
        </div>
        {titleName && (
          <div className="sub-header-section">
            <h1 className="initiatives-header-text">{titleName}</h1>
          </div>
        )}
      </div>
    </>
  );
};

// InitiativesHeader.propTypes = {
//     backButton: PropTypes.string.isRequired,
//     titleName: PropTypes.string,
// };

export default InitiativesHeader;
