import React, { useEffect } from "react";
import "./DigitalPrint.scss";
import Header from "../../Components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import digitalIcon1 from "../../Assets/Images/digital-print/digital-icon1.webp";
import digitalIcon2 from "../../Assets/Images/digital-print/digital-icon2.webp";
import digitalIcon3 from "../../Assets/Images/digital-print/digital-icon3.webp";
import digitalIcon4 from "../../Assets/Images/digital-print/digital-icon4.webp";
import digitalIcon5 from "../../Assets/Images/digital-print/digital-icon5.jpeg";
import digitalIcon6 from "../../Assets/Images/digital-print/digital-icon6.webp";
import digitalIcon7 from "../../Assets/Images/digital-print/digital-icon7.webp";
import digitalIcon8 from "../../Assets/Images/digital-print/digital-icon8.webp";
import digitalIcon9 from "../../Assets/Images/digital-print/digital-icon9.jpeg";
import digitalIcon10 from "../../Assets/Images/digital-print/digital-icon10.webp";
import digitalIcon11 from "../../Assets/Images/digital-print/digital-icon11.webp";
import digitalIcon12 from "../../Assets/Images/digital-print/digital-icon12.webp";
import digitalIcon13 from "../../Assets/Images/digital-print/digital-icon13.webp";
import digitalIcon14 from "../../Assets/Images/digital-print/digital-icon14.webp";
import digitalIcon15 from "../../Assets/Images/digital-print/digital-icon15.webp";
import digitalIcon16 from "../../Assets/Images/digital-print/digital-icon16.webp";
import digitalIcon17 from "../../Assets/Images/digital-print/digital-icon17.webp";
import digitalIcon18 from "../../Assets/Images/digital-print/digital-icon18.webp";
import digitalIcon19 from "../../Assets/Images/digital-print/digital-icon19.webp";
import digitalIcon20 from "../../Assets/Images/digital-print/digital-icon20.webp";
import digitalIcon21 from "../../Assets/Images/digital-print/digital-icon21.webp";
import digitalIcon22 from "../../Assets/Images/digital-print/digital-icon22.webp";
import digitalIcon23 from "../../Assets/Images/digital-print/digital-icon23.webp";
import digitalIcon24 from "../../Assets/Images/digital-print/digital-icon24.webp";
import digitalIcon25 from "../../Assets/Images/digital-print/digital-icon25.webp";
import digitalIcon26 from "../../Assets/Images/digital-print/digital-icon26.webp";
import digitalIcon27 from "../../Assets/Images/digital-print/digital-icon27.webp";
import digitalIcon28 from "../../Assets/Images/digital-print/digital-icon28.webp";
import digitalIcon29 from "../../Assets/Images/digital-print/digital-icon29.webp";
import digitalIcon30 from "../../Assets/Images/digital-print/digital-icon30.png";
import aug1 from "../../Assets/Images/digital-print/aug-1.png";
import { Helmet } from "react-helmet";
const galleryItems = [
  {
    id: 1,
    image: digitalIcon30,
    videoLink:
      "https://bpknew.com/the-resolution-of-united-india-will-be-fulfilled-under-the-leadership-of-prime-minister-narendra-modi-dr-verma/",
    text: "DigitalPrint.DigitalPrint_text32",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date32",
  },
  {
    id: 1,
    image: aug1,
    videoLink:
      "https://prakharpravaktanews.in/blog/5562/",
    text: "DigitalPrint.DigitalPrint_text31",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date31",
  },
  {
    id: 1,
    image: digitalIcon29,
    videoLink:
      "https://prakharpravaktanews.wordpress.com/2024/04/21/%e0%a4%b8%e0%a4%a4%e0%a4%a8%e0%a4%be-%e0%a4%b5%e0%a4%be%e0%a4%b8%e0%a4%bf%e0%a4%af%e0%a5%8b%e0%a4%82-%e0%a4%95%e0%a5%87-%e0%a4%ae%e0%a4%a8-%e0%a4%ae%e0%a5%87%e0%a4%82-%e0%a4%b8%e0%a4%bf%e0%a4%b0-2/",
    text: "DigitalPrint.DigitalPrint_text29",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date30",
  },
  {
    id: 1,
    image: digitalIcon28,
    videoLink: "https://www.youtube.com/watch?v=sozLkZ70dCM",
    text: "DigitalPrint.DigitalPrint_text28",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date28",
  },
  {
    id: 1,
    image: digitalIcon1,
    videoLink:
      "https://hindi.latestly.com/lifestyle/dr-swapna-verma-known-as-doctors-daughter-is-busy-in-the-campaign-to-conquer-the-disease-2095919.html",
    text: "DigitalPrint.DigitalPrint_text1",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date1",
  },
  {
    id: 2,
    image: digitalIcon2,
    videoLink: "https://www.bhaskarhindi.com/other/news-1008579",
    text: "DigitalPrint.DigitalPrint_text2",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date2",
  },
  {
    id: 3,
    image: digitalIcon3,
    videoLink:
      "https://jantaserishta.com/national/womens-day-special-doctor-beti-detects-the-disease-before-it-occurs-and-starts-treatment-3153503",
    text: "DigitalPrint.DigitalPrint_text3",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date3",
  },
  {
    id: 4,
    image: digitalIcon4,
    videoLink:
      "https://www.etvbharat.com/hi/!health/dr-swapna-verma-special-story-on-international-womens-day-womens-day-hin24030804388",
    text: "DigitalPrint.DigitalPrint_text4",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date4",
  },
  {
    id: 5,
    image: digitalIcon5,
    videoLink: "https://www.youtube.com/watch?v=ywOvoB-vIQc",
    text: "DigitalPrint.DigitalPrint_text5",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date5",
  },
  {
    id: 6,
    image: digitalIcon6,
    videoLink:
      "https://prakharpravaktanews.wordpress.com/2024/02/15/%E0%A4%A8%E0%A5%8D%E0%A4%AF%E0%A5%82-%E0%A4%B2%E0%A4%BF%E0%A4%9F%E0%A4%BF%E0%A4%B2-%E0%A4%AB%E0%A5%8D%E0%A4%B2%E0%A4%BE%E0%A4%B5%E0%A4%B0-%E0%A4%B8%E0%A5%8D%E0%A4%95%E0%A5%82%E0%A4%B2-%E0%A4%95/",
    text: "DigitalPrint.DigitalPrint_text6",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date6",
  },
  {
    id: 7,
    image: digitalIcon7,
    videoLink: "https://indias.news/",
    text: "DigitalPrint.DigitalPrint_text7",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date7",
  },
  {
    id: 8,
    image: digitalIcon8,
    videoLink: "https://hi.investing.com/news/general/article-113923",
    text: "DigitalPrint.DigitalPrint_text8",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date8",
  },
  {
    id: 9,
    image: digitalIcon9,
    videoLink:
      "https://www.punjabkesari.com/india-news/sadhus-and-saints-will-go-to/",
    text: "DigitalPrint.DigitalPrint_text9",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date9",
  },
  {
    id: 10,
    image: digitalIcon10,
    videoLink: "https://indias.news/",
    text: "DigitalPrint.DigitalPrint_text10",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date10",
  },
  {
    id: 11,
    image: digitalIcon11,
    videoLink: "https://www.youtube.com/watch?v=hq6iNOKR9dw",
    text: "DigitalPrint.DigitalPrint_text11",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date11",
  },
  {
    id: 12,
    image: digitalIcon12,
    videoLink:
      "https://www.facebook.com/BansalNewsOfficial/videos/1454134205528996/",
    text: "DigitalPrint.DigitalPrint_text12",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date12",
  },
  {
    id: 13,
    image: digitalIcon13,
    videoLink: "https://www.youtube.com/watch?v=tfe858jk0-U",
    text: "DigitalPrint.DigitalPrint_text13",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date13",
  },
  {
    id: 14,
    image: digitalIcon14,
    videoLink: "https://www.facebook.com/ZeeMPCG/videos/1134780507901586/",
    text: "DigitalPrint.DigitalPrint_text14",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date14",
  },
  {
    id: 15,
    image: digitalIcon15,
    videoLink: "https://www.youtube.com/watch?v=8meCDmYdQLA",
    text: "DigitalPrint.DigitalPrint_text30",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date15",
  },
  {
    id: 16,
    image: digitalIcon16,
    videoLink:
      "https://www.facebook.com/newznagrimpcg/videos/829447185349599/?extid=CL-UNK-UNK-UNK-AN_GK0T-GK1C&mibextid=ZbWKwL",
    text: "DigitalPrint.DigitalPrint_text16",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date16",
  },
  {
    id: 17,
    image: digitalIcon17,
    videoLink: "https://www.youtube.com/watch?v=3C1JMCEfw7Q",
    text: "DigitalPrint.DigitalPrint_text17",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date17",
  },
  {
    id: 18,
    image: digitalIcon18,
    videoLink: "https://www.youtube.com/watch?v=RyU3ycLhvsU",
    text: "DigitalPrint.DigitalPrint_text18",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date18",
  },
  {
    id: 19,
    image: digitalIcon19,
    videoLink:
      "https://satnatimes.in/healthy-society-has-the-biggest-contribution-in-building-a-strong-nation-dr-swapna-verma/",
    text: "DigitalPrint.DigitalPrint_text19",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date19",
  },
  {
    id: 20,
    image: digitalIcon20,
    videoLink:
      "https://rewanchalroshni.com/uncategorized/majbut-rastra-nirman/",
    text: "DigitalPrint.DigitalPrint_text20",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date20",
  },
  {
    id: 21,
    image: digitalIcon21,
    videoLink: "https://hi.investing.com/news/general/article-119587",
    text: "DigitalPrint.DigitalPrint_text21",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date21",
  },
  {
    id: 22,
    image: digitalIcon22,
    videoLink: "https://www.youtube.com/watch?v=tr2WEyuctdo",
    text: "DigitalPrint.DigitalPrint_text22",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date22",
  },
  // {
  //   id: 23,
  //   image: digitalIcon23,
  //   videoLink:
  //     "https://prakharpravaktanews.wordpress.com/2023/09/23/%E0%A4%A1%E0%A4%BE%E0%A4%95%E0%A5%8D%E0%A4%9F%E0%A4%B0-%E0%A4%B8%E0%A5%8D%E0%A4%B5%E0%A4%AA%E0%A5%8D%E0%A4%A8%E0%A4%BE-%E0%A4%B5%E0%A4%B0%E0%A5%8D%E0%A4%AE%E0%A4%BE-%E0%A4%95%E0%A5%87-%E0%A4%B8/",
  //   text: "DigitalPrint.DigitalPrint_text23",
  //   location: "",
  //   subDate: "DigitalPrint.DigitalPrint_date23",
  // },
  {
    id: 24,
    image: digitalIcon24,
    videoLink:
      "https://statebreak.in/satna-news-water-power-and-flood-control-minister-swatantra-dev-singh-boosted-everyones-morale-by-reaching-the-health-camp-of-dr-swapna-verma/",
    text: "DigitalPrint.DigitalPrint_text24",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date24",
  },
  // {
  //   id: 25,
  //   image: digitalIcon25,
  //   videoLink: "https://web.e-tvnews.in/2023/09/blog-post_30.html",
  //   text: "DigitalPrint.DigitalPrint_text25",
  //   location: "",
  //   subDate: "DigitalPrint.DigitalPrint_date25",
  // },
  {
    id: 26,
    image: digitalIcon26,
    videoLink:
      "https://www.deshbandhu.co.in/states/free-treatment-campaign-to-fulfill-modi-resolve-430644-1",
    text: "DigitalPrint.DigitalPrint_text26",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date26",
  },
  {
    id: 27,
    image: digitalIcon27,
    videoLink:
      "https://prakharpravaktanews.wordpress.com/2023/09/28/16-%E0%A4%A6%E0%A4%BF%E0%A4%A8-32-%E0%A4%B6%E0%A4%BF%E0%A4%B5%E0%A4%BF%E0%A4%B0-%E0%A4%B9%E0%A4%9C%E0%A4%BE%E0%A4%B0%E0%A5%8B%E0%A4%82-%E0%A4%B2%E0%A5%8B%E0%A4%97%E0%A5%8B%E0%A4%82-%E0%A4%A4%E0%A4%95/",
    text: "DigitalPrint.DigitalPrint_text27",
    location: "",
    subDate: "DigitalPrint.DigitalPrint_date27",
  },
];

function Gallery() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageData = useSelector((state) => state.language);
  let newObject = JSON.parse(window.localStorage.getItem("languageData"));
  useEffect(() => {
    if (newObject && newObject != null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);
  // const handleShareClick = () => {
  //   window.open("https://www.facebook.com/Drswapna.vermaa");
  //   window.open("");
  // };
  const translatedText = t("DigitalPrint.DigitalPrint_hedline");

  return (
    <div className="content-digital-print">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.drswapnaverma.com/press/digital-print"
        />
        <title>Dr. Swapna Verma: Healthcare & Social Work in India</title>
        <meta
          name="description"
          content="Dr. Swapna Verma, a renowned doctor in Satna, is known for her social work & free medical camps. She runs Madhurima Sewa Sansthan & is a BJP member. Awarded Sushruta Award for her healthcare initiatives."
        />
        <meta
          name="keywords"
          content="Free medical camps, health initiative, Women's Health, Public Service"
        />
      </Helmet>

      <div>
        <div className="digital-print">
          <br />
          <br />
          <Header title={translatedText} />
          <br />
          <br />
          <br />

          <section className="container myvoice-main-container">
            <div className="container mt-5">
              <div className="row justify-content-center">
                {galleryItems.map((item) => (
                  <div className="col-md-4   mt-5" key={item.id}>
                    <div className="digital-print-section-card">
                      <a
                        href={item.videoLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={item.image} alt="Card image cap" />
                      </a>
                      {/* <div className="">
                        <br />
                        <h5 className="card-head">{t(item.text)}</h5>
                      </div> */}
                    </div>

                    <div className="sub-text-digital">
                      <p className="card-head">{t(item.text)}</p>
                      <p className="right-side-date">{t(item.subDate)}</p>
                      {/* <img
                        src={sharingIcon}
                        className="sharing-icon"
                        onClick={handleShareClick}
                      /> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
          </section>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
