import React, { useState, useEffect } from "react";
import "./Sidebar.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import language from "../../Assets/Images/language (1).png";
import { setLanguage } from "../../Store/Action/Index";
import logo from "../../Assets/Images/logo.jpg";
import Header from "./Header";
const Sidebar = () => {
  const [mobile, setMobile] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 768);
      setMobileMenuOpen(false);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
    setIsDropdownOpen1(false);
    setIsDropdownOpen2(false);
  };

  const navigate = useNavigate();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
  const [isCrossIconVisible, setIsCrossIconVisible] = useState(false);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const sideBarIndex = sessionStorage.getItem("sideBarIndex")
    ? Number(sessionStorage.getItem("sideBarIndex"))
    : 1;
  const [activeId, setActiveId] = useState(sideBarIndex);

  const [selectedLng, setSelectedLng] = useState(() => {
    const storedLanguage = window.localStorage.getItem("languageData");
    return storedLanguage ? JSON.parse(storedLanguage).language : "hnd";
  });
  useEffect(() => {
    setSelectedLng("hnd");
    window.localStorage.setItem(
      "languageData",
      JSON.stringify({ language: "hnd" })
    );
  }, []);

  useEffect(() => {
    i18n.changeLanguage(selectedLng);
    dispatch(setLanguage({ language: selectedLng }));
    window.localStorage.setItem(
      "languageData",
      JSON.stringify({ language: selectedLng })
    );
  }, [selectedLng]);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
    setIsCrossIconVisible(!isCrossIconVisible);
  };

  const onRouteChange = (index, route) => {
    setActiveId(index);
    navigate(route);
    sessionStorage.setItem("sideBarIndex", index);
  };
  return (
    <>
      <nav className="navbar">
        <div
          className="menu-icon"
          onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
        >
          ☰
          <a href="/">
            <img src={logo} className="mobile-view-icon" />
          </a>
        </div>
        <ul
          className={
            mobile && isMobileMenuOpen ? "nav-links-mobile" : "nav-links"
          }
        >
          <NavLink
            to="/"
            className="home"
            activeClassName="active"
            onClick={closeMobileMenu}
          >
            <li>{t("header.home")}</li>
          </NavLink>
          <NavLink
            to="/about-us"
            className="About"
            activeClassName="active"
            onClick={closeMobileMenu}
          >
            <li>{t("header.about")}</li>
          </NavLink>
          <NavLink
            to="/vision-mission"
            className="About"
            activeClassName="active"
            onClick={closeMobileMenu}
          >
            <li>{t("header.MissionVision")}</li>
          </NavLink>
          <li class="nav-item dropdown show">
            <a
              class="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
              onClick={() => setIsDropdownOpen1(!isDropdownOpen1)}
            >
              {t("header.mediaGallery")}
            </a>
            {isDropdownOpen1 && (
              <div className="dropdown-menu show">
                <NavLink
                  to="/media-gallery/events"
                  className="dropdown-item pl-3"
                  activeClassName="active"
                  onClick={closeMobileMenu}
                >
                  <li>{t("header.events")}</li>
                </NavLink>
                <NavLink
                  to="/media-gallery/my-voice"
                  className="dropdown-item pl-3"
                  activeClassName="active"
                  onClick={closeMobileMenu}
                >
                  <li>{t("header.MyVoice")}</li>
                </NavLink>
                <NavLink
                  to="/media-gallery/religious-events"
                  className="dropdown-item pl-3"
                  activeClassName="active"
                  onClick={closeMobileMenu}
                >
                  <li>{t("header.dharmikkaryakram")}</li>
                </NavLink>
              </div>
            )}
          </li>
          <div>
            <span style={{ width: "181px" }}>
              <a href="/">
                <img src={logo} className="web-logo" />
              </a>
            </span>
          </div>
          <li class="nav-item dropdown show">
            <a
              class="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
              onClick={() => setIsDropdownOpen2(!isDropdownOpen2)}
            >
              {t("header.Press")}
            </a>
            {isDropdownOpen2 && (
              <div className="dropdown-menu show">
                <NavLink
                  to="/press/news-clip"
                  className="Success Stories"
                  activeClassName="active"
                  onClick={closeMobileMenu}
                >
                  <li>{t("header.newsClip")}</li>
                </NavLink>
                <NavLink
                  to="/press/digital-print"
                  className="Digital Print"
                  activeClassName="active"
                  onClick={closeMobileMenu}
                >
                  <li>{t("header.DigitalPrint")}</li>
                </NavLink>
              </div>
            )}
          </li>

          <NavLink
            to="/projects"
            className="Success Stories"
            activeClassName="active"
            onClick={closeMobileMenu}
          >
            <li>{t("header.projects")}</li>
          </NavLink>
          <NavLink
            to="/achievements"
            className="Success Stories"
            activeClassName="active"
            onClick={closeMobileMenu}
          >
            <li>{t("header.achievements")}</li>
          </NavLink>
          <NavLink
            to="/initiatives"
            className="ContactUs"
            activeClassName="active"
            onClick={closeMobileMenu}
          >
            <li>{t("header.Initiatives")}</li>
          </NavLink>
          <li className="seletion-language">
            <i>
              <img src={language} alt="Language" className="language" />
            </i>
            <select
              value={selectedLng}
              onChange={(event) => setSelectedLng(event.target.value)}
            >
              <option value="hnd">हिंदी</option>
              <option value="en">English</option>
            </select>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
