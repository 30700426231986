import React, { useEffect, useState } from "react";
import "./Press.scss";
import "../../i18n";
import { readService } from "../../Services/HttpService";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Header from "../../Components/Header/Header";
import closemodal from "../../Assets/Images/press/close-modal-icon.svg";
import BgBrown from "../../Assets/Images/press/Bg-brown.png";
import Loader from "../../Components/loaderScreen/loaderScreen";
import { Helmet } from "react-helmet";
export default function Press() {
  const [loading, setLoading] = useState(true);
  const languageData = useSelector((state) => state.language);
  const [sections, setSections] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let newObject = JSON.parse(window.localStorage.getItem("languageData"));

  useEffect(() => {
    if (newObject && newObject !== null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);
  const [activeParagraph, setActiveParagraph] = useState(0);

  useEffect(() => {
    getPressDataList();
  }, []);

  useEffect(() => {
    console.log(languageData);
  }, [languageData]);

  useEffect(() => {
    getEventDataList();
  }, []);
  const getPressDataList = async () => {
    let response = await readService("swapna/press");
    if (response) {
      setSections(response.data);
    }
    console.log("Data:", response.data);
  };
  const handleCardClick = (image) => {
    setSelectedImage(image);
    openModal();
  };
  const handleTextClick = (index) => {
    setActiveParagraph(index);
  };
  const getEventDataList = async () => {
    try {
      setLoading(true);
      let response = await readService("swapna/Press");
      console.log('response---->', response)
      if (response) {
        setSections(response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  const contentAndImages = [];
  const translatedText = t("press.press_hedline");
  return (
    <div>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.drswapnaverma.com/press/news-clip"
        />
        <title>
          Dr. Swapna Verma: Free Health Camps, Tribal Welfare & Social Work
        </title>
        <meta
          name="description"
          content="Dr. Swapna Verma, a social worker, leads health camps, provides free medical care & promotes tribal welfare in Satna. Her foundation's initiatives include Sushrut Award & Sanatan Sankalp Yatra."
        />
        <meta
          name="keywords"
          content="Disease-free India, Social Worker, Women Empowerment, Tribal Education, Digital health Profile"
        />
      </Helmet>

      <br />
      <br />
      <Header title={translatedText} />

      <br />
      <br />
      <br />
      <br />

      <div className="content-event">
        {loading && <Loader />}
        <div className="event-bg">
          <div className="container section-body">
            <div className="row d-flex justify-content-center">
              <br />
              <br />
              <br />
              {sections &&
                sections.map((section) => (
                  <div
                    key={section.pressId}
                    onClick={() => handleCardClick(section.url)}
                    className="col-md-4"
                  >
                    <div>
                      <div>
                        <div>
                          <img src={section.url} className="press-image" />
                        </div>
                      </div>
                      <div className="pres-text">
                        <p>
                          {section &&
                            (languageData?.language === "hnd"
                              ? section?.location
                              : section?.engLocation)}
                        </p>
                        <p>
                          {section &&
                            (languageData?.language === "hnd"
                              ? section?.hindiDate
                              : section?.engDate)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-start">
                        <br />
                        <br />
                        <br />
                        <br />
                        <div>
                          <h2 className="press-title">
                            {section &&
                              (languageData?.language === "hnd"
                                ? section?.hindiTitle
                                : section?.engTitle)}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <section id="press-section2">
        <div className="">
          <div className="">
            <div className="card-group">
              {contentAndImages.map((item, index) => (
                <div key={index}>
                  <div
                    className=""
                    onClick={() => handleTextClick(index)}
                    id="cardimg"
                  >
                    <br />
                    <div className=" d-flex flex-wrap " id="press-card-img">
                      <div className="container-fluid pt-5 pb-5">
                        <div className="row   ">
                          <h5 className="image-header-text ">
                            <span className="">{item.content}</span>
                          </h5>
                        </div>
                      </div>
                      {item.images.map((image, imgIndex) => (
                        <>
                          <div
                            key={imgIndex}
                            className=" col-md-3 image-with-text text-center mb-3"
                            onClick={() => handleCardClick(image)}
                          >
                            <div className="image-card">
                              <img
                                className="card-img-top rounded-0 img-fluid"
                                src={image}
                                alt={`Event ${index + 1} Image ${imgIndex + 1}`}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div
              className={`modal modal-overlay ${showModal ? "show" : ""}`}
              tabIndex="-1"
              role="dialog"
              style={{ display: showModal ? "block" : "none", zIndex: "9999" }}
            >
              <div class="row align-items-end ">
                <div class="col c-btn">
                  <img
                    src={closemodal}
                    alt="colse modal"
                    style={{ position: "absolute", top: 0, right: 2 }}
                    onClick={closeModal}
                  />
                </div>
              </div>
              <div style={{ padding: "56px" }}>
                <div>
                  <div className="modal-body">
                    <img
                      class="card-img-top rounded-0 img-fluid"
                      src={selectedImage}
                      alt="Card image cap"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </section>
    </div>
  );
}
