import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from '../src/Assets/i18n/english/common.json';
import translationHND from '../src/Assets/i18n/hindi/common.json';

// the translations
const resources = {
    en: {
        translation: translationEN
      },
    hnd : {
        translation: translationHND
      }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "hnd",

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;