import React, { useEffect, useState } from "react";
import "./MissionVision.scss";
import mainImage from "../../Assets/Images/mission-vision/mainImage.webp";
import hypothesisImage from "../../Assets/Images/mission-vision/visiopn-2nd.webp";
import highlightLine from "../../Assets/Images/mission-vision/line2.svg";
import Rectangle from "../../Assets/Images/mission-vision/Rectangle.webp";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function MissionVision() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageData = useSelector((state) => state.language);
  const [index, setIndex] = useState(0);
  let newObject = JSON.parse(window.localStorage.getItem("languageData"));
  useEffect(() => {
    if (newObject && newObject !== null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.drswapnaverma.com/vision-mission"
        />
        <title>Empowering India: Health, Education, & Progress</title>
        <meta
          name="description"
          content="A healthy, prosperous India. Learn how we're working towards accessible medical education, economic empowerment, & digital agriculture."
        />
        <meta
          name="keywords"
          content="healthcare in India, medical education, digital farming, affordable healthcare, women's empowerment, Medical education in India, Digital agriculture India, Affordable healthcare India, Medical Education program"
        />
      </Helmet>
      <section>
        <br />
        <br />
        <br />
        <img src={mainImage} className="w-100" />
        <div className="mission-text">
          <h1>{t("newMission.newMissionText1")}</h1>
        </div>
      </section>
      <section>
        <div className="container vision-section">
          <div className="text-center mb-4">
            <h2>{t("newMission.newMissionText2")}</h2>
            <img
              src={highlightLine}
              className="justify-content-center"
              alt="Highlight Line"
            />
          </div>
          <div className="row justify-content-center d-flex">
            <div className="col-lg-1"></div>
            <div className="col-lg-6">
              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText3")}</p>
              </div>

              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText4")}</p>
              </div>

              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText5")}</p>
              </div>
              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText6")}</p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText7")}</p>
              </div>
              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText8")}</p>
              </div>
              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText9")}</p>
              </div>
              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText10")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <img src={hypothesisImage} className="w-100" />
      </section>
      <section>
        <div className="container vision-section">
          <div className="text-center mb-4">
            <h2>{t("newMission.newMissionText11")}</h2>
            <img
              src={highlightLine}
              className="justify-content-center"
              alt="Highlight Line"
            />
          </div>
          <div className="row justify-content-center d-flex">
            <div className="col-lg-6  ">
              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText12")}</p>
              </div>
              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText13")}</p>
              </div>
              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText14")}</p>
              </div>
              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText15")}</p>
              </div>
              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText16")}</p>
              </div>
            </div>
            <div className="col-lg-5  ">
              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText17")}</p>
              </div>
              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText18")}</p>
              </div>
              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText19")}</p>
              </div>
              <div className="mission-new-text">
                <img
                  src={Rectangle}
                  className="img-fluid rectangle-left-side-icon"
                />
                <p>{t("newMission.newMissionText20")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MissionVision;
