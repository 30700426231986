import React, { useEffect } from "react";
import "./Achievements.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../i18n";
import Header from "../../Components/Header/Header";
import { Helmet } from "react-helmet";

export default function Achievements() {
  const { t, i18n } = useTranslation();
  const languageData = useSelector((state) => state.language);
  console.log("Language data is" + JSON.stringify(languageData));

  let newObject = JSON.parse(window.localStorage.getItem("languageData"));
  useEffect(() => {
    if (newObject && newObject != null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);
  const translatedText = t("achievements.achievementsEvent");
  return (
    <div id="achievements-container">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.drswapnaverma.com/achievements"
        />
        <title>Dr. Swapna Verma: Awards & Recognition in Healthcare</title>
        <meta
          name="description"
          content="Dr. Swapna Verma, a leader in Disease-Free India, wins Pride of Vindhya & Sushrut Awards. Recognized for digital education & healthcare initiatives."
        />
        <meta
          name="keywords"
          content="Disease Free India, Digital Education Platform, free health camps"
        />
      </Helmet>
      <br />
      <br />
      <Header title={translatedText} />
      <br />
      <br />
      <br />
      <br />
      <section className="achievments-section">
        <div className="green-main-content">
          <div className="green-box">
            <p className="swapna-verma-main-heading">
              {t("achievements.achievementsEvent1")}
            </p>
          </div>
        </div>
        <div className="sv-background-container">
          <div className="sv-text-container">
            <p>{t("achievements.achievementsEvent2")}</p>
            <br />
            <p className="sv-sub-text">
              {" "}
              {t("achievements.achievementsEvent12")}
            </p>
          </div>
        </div>
      </section>
      <section class="timeline">
        <div class="last-line-midpoint"></div>
        <div class="timeline-block">
          <div class="timeline-midpoint"></div>

          <div class="timeline-content timeline-content--left">
            <p class="timeline-year" style={{ fontSize: "11px" }}>
              <h3 className="line-year-right">2023</h3>
              {t("achievements.achievements2")}
            </p>
            <p class="timeline-text--left" style={{ fontSize: "11px" }}>
              <h3 className="line-year">2024</h3>
              {t("achievements.achievements1")}
            </p>
          </div>
        </div>
        <div class="timeline-block">
          <div class="timeline-midpoint"></div>

          <div class="timeline-content timeline-content--left">
            <p class="timeline-year" style={{ fontSize: "11px" }}>
              <h3 className="line-year-right">2022</h3>
              {t("achievements.achievements3")}
            </p>
            <p class="pb-2 timeline-text--left" style={{ fontSize: "11px" }}>
              <h3 className="line-year">2023</h3>
              {t("achievements.achievements4")}
            </p>
          </div>
        </div>
        <div class="timeline-block">
          <div class="timeline-midpoint"></div>
          <div class="timeline-content timeline-content--left">
            <p class="timeline-year" style={{ fontSize: "11px" }}>
              <h3 className="line-year-right">2022</h3>
              {t("achievements.achievements5")}
            </p>
            <p class="timeline-text--left" style={{ fontSize: "11px" }}>
              <h3 className="line-year">2022</h3>
              {t("achievements.achievements6")}
            </p>
          </div>
        </div>

        <div class="timeline-block">
          <div class="timeline-midpoint"></div>

          <div class="timeline-content timeline-content--left">
            <p class="timeline-year " style={{ fontSize: "11px" }}>
              <h3 className="line-year-right">2021</h3>
              {t("achievements.achievements7")}
            </p>

            <p class="timeline-text--left " style={{ fontSize: "11px" }}>
              <h3 className="line-year">2021</h3>
              {t("achievements.achievements8")}
            </p>
          </div>
        </div>
        <div class="timeline-block">
          <div class="timeline-midpointed">
            <span className="midpointed"></span>
          </div>

          <div class="timeline-content timeline-content--left">
            <p class="timeline-text--left " style={{ fontSize: "11px" }}>
              <h3 className="line-year">2020</h3>
              {t("achievements.achievements9")}
            </p>
          </div>
        </div>
        <div class="first-line-midpoint"></div>
      </section>
      <section className="timeliner">
        <div className="last-line-midpoints"></div>
        <div className="timeliner-blocker">
          <div className="timeliner-midpoints"></div>
          <div className="timeliner-content timeliner-content--left">
            <p className="timeliner-years">
              <h3 className="line-years-right">2024</h3>
              {t("achievements.achievements1")}
            </p>
          </div>
        </div>
        <div className="timeliner-blocker">
          <div className="timeliner-midpoints"></div>
          <div className="timeliner-content timeliner-content--left">
            <p className="timeliner-years">
              <h3 className="line-years-right">2023</h3>
              {t("achievements.achievements2")}
            </p>
          </div>
        </div>
        <div className="timeliner-blocker">
          <div className="timeliner-midpoints"></div>
          <div className="timeliner-content timeliner-content--left">
            <p className="timeliner-years">
              <h3 className="line-years-right">2023</h3>
              {t("achievements.achievements4")}
            </p>
          </div>
        </div>
        <div className="timeliner-blocker">
          <div className="timeliner-midpoints"></div>
          <div className="timeliner-content timeliner-content--left">
            <p className="timeliner-years">
              <h3 className="line-years-right">2022</h3>
              {t("achievements.achievements3")}
            </p>
          </div>
        </div>
        <div className="timeliner-blocker">
          <div className="timeliner-midpoints"></div>
          <div className="timeliner-content timeliner-content--left">
            <p className="timeliner-years">
              <h3 className="line-years-right">2022</h3>
              {t("achievements.achievements6")}
            </p>
          </div>
        </div>
        <div className="timeliner-blocker">
          <div className="timeliner-midpoints"></div>
          <div className="timeliner-content timeliner-content--left">
            <p className="timeliner-years">
              <h3 className="line-years-right">2022</h3>
              {t("achievements.achievements5")}
            </p>
          </div>
        </div>
        <div className="timeliner-blocker">
          <div className="timeliner-midpoints"></div>
          <div className="timeliner-content timeliner-content--left">
            <p className="timeliner-years">
              <h3 className="line-years-right">2021</h3>
              {t("achievements.achievements8")}
            </p>
          </div>
        </div>
        <div className="timeliner-blocker">
          <div className="timeliner-midpoints"></div>
          <div className="timeliner-content timeliner-content--left">
            <p className="timeliner-years">
              <h3 className="line-years-right">2021</h3>
              {t("achievements.achievements7")}
            </p>
          </div>
        </div>
        <div className="timeliner-blocker">
          <div className="timeliner-midpoints"></div>

          <div className="timeliner-content timeliner-content--left">
            <p className="timeliner-years ">
              <h3 className="line-years-right">2020</h3>
              {t("achievements.achievements9")}
            </p>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="first-line-midpoints"></div>
      </section>
    </div>
  );
}
