import React from "react";
import "./Initiatives.scss";
import Header from "../../Components/Header/Header";
import Initiaimg5 from "../../Assets/Images/initiative/img-5.webp";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import backIcon from "../../Assets/Images/initiative/back-icon.svg";
import { useNavigate } from "react-router-dom";
import InitiativesHeader from "./InitiativesHeader";
import { Helmet } from "react-helmet";
import SubHeader from "../../Components/Header/SubHeader";
export default function HealthAwareness() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageData = useSelector((state) => state.language);
  let newObject = JSON.parse(window.localStorage.getItem("languageData"));
  useEffect(() => {
    if (newObject && newObject != null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);
  const navigate = useNavigate();
  const navigatePage = () => {
    navigate("/initiatives");
  };
  const translatedText = t("initiatives.initiatives_text");
  const backBtn = t("event.backButton");
  const maintaxt = t("initiatives.social_text4");
  return (
    <div className="content-initiative">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.drswapnaverma.com/health-awareness"
        />
        <title>Building Healthy Villages | Health Awareness Programs</title>
        <meta
          name="description"
          content="Dr. Swapna Verma leads health awareness initiatives in rural India. Learn about disease prevention, women's health, and the Disease-Free India Campaign."
        />
        <meta
          name="keywords"
          content="Disease Free India Campaign, Women's Health Initiatives, Disease Prevention, Rural Healthcare, Rural Health Awareness Programs, healthcare education, women's health"
        />
      </Helmet>
      <br />
      <br />
      <SubHeader title={translatedText} />
      <InitiativesHeader backButton={backBtn} titleName={maintaxt} />
      <div className="bg-all-Initiatives">
        <br />
        <br />
        <div className="container custom-container mt-4 text-center">
          <div className="row">
            <h3 className="main-title">{t("initiatives.awareness")}</h3>
            <br />
            <br />
            <div>
              <a
                href="https://www.youtube.com/watch?v=iqNJmikn8as&feature=youtu.be"
                target="_blank"
              >
                <img src={Initiaimg5} className="img-fluid main-header-img" />
              </a>
              <br />
              <br />
            </div>
            <div>
              <div className="justify-content">
                <span className="">
                  {t("initiatives.awareness1")}
                  <br />
                  <br />
                  {t("initiatives.awareness2")}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="justify-content">
              <span> {t("initiatives.awareness3")}</span>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}
