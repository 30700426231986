import React from "react";
import "../Header/Header.scss";

const SubHeader = ({ title, subTitle }) => (
  <>
    <div id="header_title">
      <div className="container">
        <p className="title-header">{title}</p>
      </div>
      {subTitle && (
        <div className="sub-header-section1">
          <p className="sub-header">{subTitle}</p>
        </div>
      )}
    </div>
  </>
);

export default SubHeader;
