import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../Header/Header.scss";

const MyVoiceHeader = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let newObject = JSON.parse(window.localStorage.getItem("languageData"));

  useEffect(() => {
    if (newObject && newObject !== null) {
      i18n.changeLanguage(newObject.language);
    }
  }, [i18n, newObject]);

  const handleClickMyVoice = () => {
    console.log("handleClick called");
    navigate("/media-gallery/my-voice");
  };

  const handleClickGallery = () => {
    console.log("handleClick called");
    navigate("/media-gallery/a-glimpse");
  };

  return (
    <>
      <div id="myvoice-header">
        <div className="container">
          <div className="tab-group">
            <p
              onClick={handleClickMyVoice}
              className={`myvoice-btn ${location.pathname === "/media-gallery/my-voice" ? "active" : ""}`}
            >
              <Link to="/media-gallery/my-voice">{t("MyVoice.header-text")}</Link>
            </p>
            <p
              onClick={handleClickGallery}
              className={`gallery-btn ${location.pathname === "/media-gallery/a-glimpse" ? "active" : ""}`}
            >
              <Link to="/media-gallery/a-glimpse">{t("header.gallery")}</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyVoiceHeader;
