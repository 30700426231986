import React, { useEffect, useState } from "react";
import "./dharmikKaryakram.scss";
import Header from "../../Components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import img1 from "../../Assets/Images/dharmikKaryakram/img-1.webp";
import img2 from "../../Assets/Images/dharmikKaryakram/img-2.webp";
import img3 from "../../Assets/Images/dharmikKaryakram/img-3.webp";
import img4 from "../../Assets/Images/dharmikKaryakram/img-4.webp";
import img5 from "../../Assets/Images/dharmikKaryakram/img-5.webp";
import img6 from "../../Assets/Images/dharmikKaryakram/img-6.webp";
import img7 from "../../Assets/Images/dharmikKaryakram/img-7.webp";
import img8 from "../../Assets/Images/dharmikKaryakram/img-8.webp";
import img9 from "../../Assets/Images/dharmikKaryakram/img-9.webp";
import img10 from "../../Assets/Images/dharmikKaryakram/img-10.webp";
import img11 from "../../Assets/Images/dharmikKaryakram/img-11.webp";
import img12 from "../../Assets/Images/dharmikKaryakram/img-12.webp";
import img14 from "../../Assets/Images/dharmikKaryakram/imag-14.webp";
import img15 from "../../Assets/Images/dharmikKaryakram/img-15.webp";
import img16 from "../../Assets/Images/dharmikKaryakram/img-16.webp";
import img17 from "../../Assets/Images/dharmikKaryakram/img17.webp";
import img18 from "../../Assets/Images/dharmikKaryakram/img18.webp";
import img19 from "../../Assets/Images/dharmikKaryakram/img19.webp";
import img20 from "../../Assets/Images/dharmikKaryakram/img20.webp";
import img21 from "../../Assets/Images/dharmikKaryakram/img21.webp";
import img22 from "../../Assets/Images/dharmikKaryakram/img22.webp";
import img23 from "../../Assets/Images/dharmikKaryakram/img23.webp";
import img24 from "../../Assets/Images/dharmikKaryakram/img24.webp";
import img25 from "../../Assets/Images/dharmikKaryakram/img25.webp";
import img26 from "../../Assets/Images/dharmikKaryakram/img26.webp";
import img27 from "../../Assets/Images/dharmikKaryakram/img27.webp";
import img28 from "../../Assets/Images/dharmikKaryakram/img28.webp";
import img29 from "../../Assets/Images/dharmikKaryakram/img29.webp";
import img30 from "../../Assets/Images/dharmikKaryakram/img30.webp";
import img31 from "../../Assets/Images/dharmikKaryakram/img31.webp";
import img34 from "../../Assets/Images/dharmikKaryakram/img34.webp";
import img35 from "../../Assets/Images/dharmikKaryakram/img35.webp";
import img36 from "../../Assets/Images/dharmikKaryakram/img36.webp";
import backIocn from "../../Assets/Images/events/back-icon.svg";
import { Carousel, CloseButton, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
function DharmikKaryakram() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageData = useSelector((state) => state.language);
  let newObject = JSON.parse(window.localStorage.getItem("languageData"));

  useEffect(() => {
    if (newObject && newObject !== null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);

  const imgUrls = [
    {
      title: "dharmikKaryakram.dharmik1",
      images: [img34, img35, img36],
    },
    {
      title: "dharmikKaryakram.dharmik2",
      images: [img1, img2, img3, img4, img5, img6],
    },
    {
      title: "dharmikKaryakram.dharmik3",
      images: [img7, img8, img9, img10],
    },
    {
      title: "dharmikKaryakram.dharmik4",
      images: [img11, img12, img14],
    },
    {
      title: "dharmikKaryakram.dharmik5",
      images: [img15, img16],
    },
    {
      title: "dharmikKaryakram.dharmik6",
      images: [img17, img18, img19, img20],
    },
    {
      title: "dharmikKaryakram.dharmik7",
      images: [img21, img22, img23],
    },
    {
      title: "dharmikKaryakram.dharmik8",
      images: [img24, img25],
    },
    {
      title: "dharmikKaryakram.dharmik9",
      images: [img26, img27, img28, img29, img30, img31],
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [modalIndex, setModalIndex] = useState(0);
  const [showDharmikSection, setShowDharmikSection] = useState(true);

  const handleCarouselSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === imgUrls[modalIndex].images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [modalIndex, imgUrls]);
  const handleImageClick = (index) => {
    setModalIndex(index);
    setActiveIndex(0);
    setShowDharmikSection(false);
  };

  const handleCarouselClose = () => {
    setShowDharmikSection(true);
  };
  const closeCarousel = () => {
    setShowDharmikSection(true);
  };
  return (
    <div className="dharmik-karyakram">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.drswapnaverma.com/media-gallery/religious-events"
        />
        <title>Madhurima Sewa Sanskar: Events for Ram Rajya</title>
        <meta
          name="description"
          content="Madhurima Sewa Sanskar Foundation works to promote family harmony & women's empowerment for a society inspired by Ram Rajya. See their events in Satna & Chitrakoot."
        />
        <meta
          name="keywords"
          content="Family harmony, Women's empowerment, Satna, Chitrakoot, Indigenous communities"
        />
      </Helmet>
      <section>
        <br />
        <br />
        <Header title={t("header.dharmikkaryakram")} />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        {showDharmikSection && (
          <div className="container dharmik-section">
            <div className="row img-content justify-content-center">
              {imgUrls.map((url, index) => (
                <div key={index} className="text-center">
                  <h5 className="image-title">{t(url.title)}</h5>
                  <br />
                  <div className="row" style={{ justifyContent: "center" }}>
                    {url.images.map((img, imgIndex) => (
                      <div
                        key={imgIndex}
                        className="col-lg-4 mb-4 image-container"
                        onClick={() => handleImageClick(index)}
                      >
                        <img
                          src={img}
                          className="dharmik-image mx-auto"
                          alt={`Image ${imgIndex + 1}`}
                        />
                      </div>
                    ))}
                  </div>
                  <br />
                  <div className="hr-lined">{/* <hr /> */}</div>
                  <br />
                </div>
              ))}
            </div>
          </div>
        )}
        {!showDharmikSection && (
          <div className="container">
            <span className="back-sapn" onClick={closeCarousel}>
              <img src={backIocn} className="back-sapn-icon" />
              {t("event.backButton")}
            </span>
            <div style={{ textAlign: "center" }}>
              <h5 className="carosel-text">{t(imgUrls[modalIndex].title)}</h5>
            </div>
            <Carousel
              className="Carousel-images"
              activeIndex={activeIndex}
              onSelect={handleCarouselSelect}
              interval={3000}
              onHide={handleCarouselClose}
            >
              {imgUrls[modalIndex].images.map((img, imgIndex) => (
                <Carousel.Item key={imgIndex}>
                  <img
                    className="d-block w-100"
                    src={img}
                    alt={`Image ${imgIndex + 1}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
            <br />
          </div>
        )}
      </section>
    </div>
  );
}

export default DharmikKaryakram;
