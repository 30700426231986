import React from "react";
import "./Footer.scss";
import fbIcon from "../../Assets/Images/Footer/fb-icon.svg";
import insta from "../../Assets/Images/Footer/insta.svg";
import kooIcon from "../../Assets/Images/Footer/koo-icon.webp";
import linkdin from "../../Assets/Images/Footer/linkdin.webp";
import logoBlack from "../../Assets/Images/Footer/logo-black.webp";
import youtub from "../../Assets/Images/Footer/youToub.webp";

export default function Footer() {
  return (
    <div className="footer">
      <div className="container-fluid footer-container  ">
        <div class="container">
          <div class="row">
            <div class="col-md-4 footer-icons mt-3">
              <a
                href="https://www.facebook.com/Drswapna.vermaa"
                target="_blank">
                <img src={fbIcon} className="img-logo" alt="Facebook" />
              </a>
              <a
                href="https://www.instagram.com/drswapnaverma/"
                target="_blank">
                <img src={insta} className="img-logo" alt="Instagram" />
              </a>
              <a href="https://twitter.com/Dr_SwapnaVerma" target="_blank">
                <img src={logoBlack} className="img-logo" alt="Logo" />
              </a>
              <a
                href="https://www.linkedin.com/in/drswapnaverma/"
                target="_blank">
                <img src={linkdin} className="img-logo" alt="LinkedIn" />
              </a>
              <a href="https://www.youtube.com/@drswapnaverma" target="_blank">
                <img src={youtub} className="youtub" alt="YouTube" />
              </a>
              <a
                href="https://www.kooapp.com/profile/drswapnaverma"
                target="_blank">
                <img src={kooIcon} className="koo" alt="Koo" />
              </a>
            </div>
            <div
              class="col"
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "15px",
              }}>
              <p>@ Copyright Reserved 2024</p>
            </div>
          </div>
        </div>
      </div>

      <section className="floating-icons-section">
        <div className="floating-icons">
          <a href="https://www.facebook.com/Drswapna.vermaa" target="_blank">
            <img src={fbIcon} className="flotting-logo" alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/drswapnaverma/" target="_blank">
            <img src={insta} className="flotting-logo" alt="Instagram" />
          </a>
          <a href="https://twitter.com/Dr_SwapnaVerma" target="_blank">
            <img src={logoBlack} className="flotting-logo" alt="Logo" />
          </a>
          <a href="https://www.linkedin.com/in/drswapnaverma/" target="_blank">
            <img src={linkdin} className="flotting-logo" alt="LinkedIn" />
          </a>
          <a href="https://www.youtube.com/@drswapnaverma" target="_blank">
            <img src={youtub} className=" youtub  " alt="YouTube" />
          </a>
          <a
            href="https://www.kooapp.com/profile/drswapnaverma"
            target="_blank">
            <img src={kooIcon} className="koo" alt="Koo" />
          </a>
        </div>
      </section>
    </div>
  );
}
