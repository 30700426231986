import React, { useEffect, useState } from "react";
import "./About.scss";
import aboutbg from "../../Assets/Images/about/about-hearo-img.webp";
import aboutbg1 from "../../Assets/Images/about/about-hearo-img1.webp";
import highlightLine from "../../Assets/Images/mission-vision/line2.svg";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import rightArrow from "../../Assets/Images/Home/right-side.svg";
import leftArrow from "../../Assets/Images/Home/left-side.svg";
import { Helmet } from "react-helmet";
function About() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageData = useSelector((state) => state.language);
  const [index, setIndex] = useState(0);
  let newObject = JSON.parse(window.localStorage.getItem("languageData"));
  useEffect(() => {
    if (newObject && newObject !== null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content-about">
      <Helmet>
        <link rel="canonical" href="https://www.drswapnaverma.com/about-us" />
        <title>Dr. Swapna Verma: Pioneering Healthcare Innovations for a Healthy India</title>
        <meta name="description" content="Dr. Swapna Verma's initiatives in healthcare and medical education, driven by PM Narendra Modi's vision for a disease-free India. Learn about her innovations in digital medicine and efforts in rural healthcare." />
        <meta name="keywords" content="Disease-Free India, Medical Education, Digital healthcare, Preventive healthcare, Rural healthcare, Digital healthcare, Rural healthcare, Medical Innovation, Digital Medical Education, Public Health Initiatives" />
      </Helmet>
      <div>
        <section>
          <br />
          <br />
          <br />
          <img src={aboutbg1} className="w-100" />
          <div className="main-screen-text">
            {/* <h2>परिचय</h2> */}
            <h1> {t("about.aboutEvent1")} </h1>
          </div>
        </section>

        <section>
          <div className="swapna-verma-text">
            <div className="container about-text-container" style={{}}>
              {/* <h2 className="main-text"></h2>
              <img src={highlightLine} className="justify-content-center" alt="Highlight Line" /> */}

              <div className="text-center mb-4">
                <h2 className="main-text">
                  {/* स्वस्थ भारत, समृद्ध भारत है डॉ. स्‍वप्ना का स्वप्ना */}
                  {t("about.aboutEvent2")}
                </h2>
                <img
                  src={highlightLine}
                  className="justify-content-center"
                  alt="Highlight Line"
                />
              </div>

              <br />
              <p>{t("about.aboutEvent3")}</p>
              <h2 className="main-hedding">
                <p>{t("about.hedding14")}</p>
              </h2>
              <p>{t("about.career_text13")}</p>
              <h2 className="main-hedding">
                <p>{t("about.hedding1")}</p>
              </h2>
              <p>{t("about.about_text")}</p>
              <h2 className="main-hedding">
                <p>{t("about.hedding2")}</p>
              </h2>
              <p>{t("about.about_text1")}</p>
              <p>{t("about.about_text2")}</p>
              <p>{t("about.career_text")}</p>
              <h2 className="main-hedding">
                <p>{t("about.hedding3")}</p>
              </h2>
              <p>{t("about.career_text1")}</p>
              <h2 className="main-hedding">
                <p>{t("about.hedding4")}</p>
              </h2>
              <p>{t("about.career_text2")}</p>
              <p>{t("about.career_text_3")}</p>
              <h2 className="main-hedding">
                <p>{t("about.hedding5")}</p>
              </h2>
              <p>{t("about.career_text3")}</p>
              <h2 className="main-hedding">
                <p>{t("about.hedding6")}</p>
              </h2>
              <p>{t("about.career_text4")}</p>
              <h2 className="main-hedding">
                <p>{t("about.hedding7")}</p>
              </h2>
              <p>{t("about.career_text5")}</p>
              <h2 className="main-hedding">
                <p>{t("about.hedding8")}</p>
              </h2>
              <p>{t("about.career_text6")}</p>
              {/* <h2 className="main-hedding">
                <p>{t("about.hedding9")}</p>
              </h2>
              <p>{t("about.career_text7")}</p>
              <br /> */}
              <h2 className="main-hedding">
                <p>{t("about.hedding10")}</p>
              </h2>
              <p>{t("about.career_text8")}</p>
              <br />
              {/* <h2 className="main-hedding">
                <p>{t("about.hedding11")}</p>
              </h2>
              <p>{t("about.career_text9")}</p>
              <p>{t("about.career_text10")}</p>
              <p>{t("about.career_text11")}</p>
              <br /> */}
              <h2 className="main-hedding">
                <p>{t("about.hedding12")}</p>
              </h2>
              <p>{t("about.career_text12")}</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default About;
