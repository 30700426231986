import React, { useEffect } from "react";
import "./Initiatives.scss";
import Header from "../../Components/Header/Header";
import initiativesIcon from "../../Assets/Images/initiative/initiatives-icon.webp";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function Initiatives() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageData = useSelector((state) => state.language);
  let newObject = JSON.parse(window.localStorage.getItem("languageData"));
  useEffect(() => {
    if (newObject && newObject != null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);

  const navigate = useNavigate();

  const goToHealthcarePage = () => {
    navigate("/healthcare");
  };
  const goToAgriculturePage = () => {
    navigate("/agriculture");
  };
  const goToEducationPage = () => {
    navigate("/education");
  };
  const goToHealthAwarenessPage = () => {
    navigate("/health-awareness");
  };
  const goToWomenEmpowermentPage = () => {
    navigate("/women");
  };
  const goToRamRajya = () => {
    navigate("/establishment-of-ram-rajya");
  };
  const translatedText = t("initiatives.initiatives_text");
  return (
    <div className="content-initiative">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.drswapnaverma.com/initiatives"
        />
        <title>Building a Better India: Key Initiatives</title>
        <meta
          name="description"
          content="Explore key initiatives for a stronger India, including establishing Ram Rajya, improving healthcare, agriculture, education, health awareness, and women's empowerment. Learn more!"
        />
        <meta
          name="keywords"
          content="Ram Rajya, Healthcare in India, Health Awareness Programs, Education Reform in India, Women Empowerment India"
        />
      </Helmet>
      <br />
      <br />

      <Header title={translatedText} />
      <br />
      <br />
      <div className="initiative-main-container">
        <div className="container justify-content-center d-flex">
          <div style={{ width: "1169px" }}>
            <div className="d-flex justify-content-center">
              <div className="col-md-4   ram-rajya d-flex justify-content-center ">
                <h5
                  style={{ marginTop: "10px", fontWeight: "bold" }}
                  onClick={goToRamRajya}
                >
                  {t("initiatives.social_text6")}
                </h5>
              </div>
            </div>
            <div className="d-flex justify-content-center  ">
              <div className="col-md-2 healthcare  ">
                <h5 className="text-initv" onClick={goToHealthcarePage}>
                  {t("initiatives.social_text1")}
                </h5>
              </div>
              <div className="col-md-4">
                <img src={initiativesIcon} className="initiative-img " />
              </div>
              <div className="col-md-1 agriculture d-flex justify-content-center ">
                <h5 onClick={goToAgriculturePage} className="agre-text-helth">
                  {t("initiatives.social_text2")}
                </h5>
              </div>
            </div>
            <div className="d-flex justify-content-center" id="initiative-text">
              <div className="col-md-1 education">
                <h5 onClick={goToEducationPage} className="agre-text-helth">
                  {t("initiatives.social_text3")}
                </h5>
              </div>
              <div className="col-md-3  d-flex justify-content-center health-awareness">
                <h5
                  className="agre-text-helth"
                  onClick={goToHealthAwarenessPage}
                >
                  {t("initiatives.social_text4")}
                </h5>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <div className="col-md-4   women-empowerment d-flex justify-content-center ">
                <h5
                  style={{ marginTop: "10px", fontWeight: "bold" }}
                  onClick={goToWomenEmpowermentPage}
                >
                  {t("initiatives.social_text5")}
                </h5>
              </div>
            </div>

            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
