import React from "react";
import "./Initiatives.scss";
import Initiaimg3 from "../../Assets/Images/initiative/img-3.webp";
import Initiaimg4 from "../../Assets/Images/initiative/img-4.webp";
import EducationIcon from "../../Assets/Images/initiative/education-icon-1.webp";
import backIcon from "../../Assets/Images/initiative/back-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InitiativesHeader from "./InitiativesHeader";
import SubHeader from "../../Components/Header/SubHeader";
import { Helmet } from "react-helmet";
export default function Education() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageData = useSelector((state) => state.language);
  let newObject = JSON.parse(window.localStorage.getItem("languageData"));
  useEffect(() => {
    if (newObject && newObject != null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);

  const translatedText = t("initiatives.initiatives_text");
  const navigate = useNavigate();
  const navigatePage = () => {
    navigate("/initiatives");
  };
  const backBtn = t("event.backButton");
  const maintaxt = t("initiatives.social_text3");
  return (
    <div className="content-initiative">
      <Helmet>
        <link rel="canonical" href="https://www.drswapnaverma.com/education" />
        <title>
          Dr. Swapna Verma: Revolutionizing Medical Education Through Digital
          Transformation
        </title>
        <meta
          name="description"
          content="Dr. Swapna Verma's pioneering work in converting 3D medical education to accessible online courses transformed learning for medical professionals during COVID & beyond."
        />
        <meta
          name="keywords"
          content=" Digital Medical Education, Medical Training, Online Learning Platforms, COVID-19 Response, 3D Medical Visualization, RGUHS University"
        />
      </Helmet>
      <br />
      <br />
      <SubHeader title={translatedText} />
      <div>
        <InitiativesHeader backButton={backBtn} titleName={maintaxt} />
      </div>
      <div className="bg-all-Initiatives">
        <br />
        <br />
        <div className="container custom-container mt-4 text-center">
          <div className="row">
            <h3 className="main-title">{t("initiatives.eduText9")}</h3>
            <br />
            <br />
            <div>
              {/* <a
                href="https://drive.google.com/file/d/1B8FIStl3eOg18DROM2TZ-xAA-trA1SU5/view"
                target="_blank"> */}
              <img src={EducationIcon} className="img-fluid education-img" />
              {/* </a> */}
            </div>
            <div className="justify-content">
              <br />
              <span className="">{t("initiatives.eduText10")}</span>
            </div>
          </div>
          <br />

          <div className="row">
            <h3 className="main-title">{t("initiatives.eduText")}</h3>
            <br />
            <br />
            <div>
              <a
                href="https://drive.google.com/file/d/1B8FIStl3eOg18DROM2TZ-xAA-trA1SU5/view"
                target="_blank"
              >
                <img src={Initiaimg3} className="img-fluid education-img" />
              </a>
            </div>
            <div className="justify-content">
              <br />
              <span className="">{t("initiatives.eduText1")}</span>
            </div>
          </div>
          <div className="row">
            <div>
              <div className="justify-content">
                <span>
                  {t("initiatives.eduText2")}
                  <br />
                  <br />
                  {t("initiatives.eduText3")}
                  <br />
                  <br />
                  {t("initiatives.eduText4")}
                </span>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <br />
            <br />
            <h3 className="main-title">{t("initiatives.eduText_4")}</h3>
            <br />
            <div>
              <a
                href="https://www.youtube.com/watch?v=Pv1tEwmOnWg&feature=youtu.be"
                target="_blank"
              >
                <img src={Initiaimg4} className="health-img education-img" />
              </a>
            </div>
            <div>
              <div>
                <div className="justify-content">
                  <br />
                  <span className=""> {t("initiatives.eduText5")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div>
              <div className="justify-content">
                <span>{t("initiatives.eduText6")}</span>
                <br />
                <br />
                <span>{t("initiatives.eduText7")}</span>
                <br />
                {/* <span>{t("initiatives.eduText8")}</span> */}
              </div>
            </div>
            <div className="col-md-1 col-sm-12"></div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}
