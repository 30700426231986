import React, { useState, useEffect, useRef } from "react";
import "./Home.scss";
import contactLable from "../../Assets/Images/Home/contact-lable.webp";
import engLable from "../../Assets/Images/Home/contact-lable1.webp";
import lineIcon from "../../Assets/Images/Home/line2.svg";
import Journeyimg1 from "../../Assets/Images/Home/jurney-img1.webp";
import homecontentImg1 from "../../Assets/Images/Home/home-img-1.webp";
import homecontentImg10 from "../../Assets/Images/Home/home-img-8.webp";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import slide1 from "../../Assets/Images/Home/slide-1.webp";
import card1 from "../../Assets/Images/Home/card-img-1.webp";
import card9 from "../../Assets/Images/Home/card-1.webp";
import card2 from "../../Assets/Images/Home/card-img-2.webp";
import card5 from "../../Assets/Images/Home/card-img-5.webp";
import card6 from "../../Assets/Images/Home/card-img-6.webp";
import card7 from "../../Assets/Images/Home/card-img-7.webp";
import slide2 from "../../Assets/Images/Home/slide-2.png";
import slide3 from "../../Assets/Images/Home/slide-3.png";
import slide4 from "../../Assets/Images/Home/slide-4.png";
import slide5 from "../../Assets/Images/Home/slide-5.webp";
import slide6 from "../../Assets/Images/Home/slide-6.png";
import jurney1 from "../../Assets/Images/Home/jurney-img1.webp";
import jurney2 from "../../Assets/Images/Home/jurney-img2.webp";
import jurney3 from "../../Assets/Images/Home/jurney-img3.webp";
import jurney4 from "../../Assets/Images/Home/jurney-img4.webp";
import nineIcon1 from "../../Assets/Images/Home/nine-icon1.webp";
import nineIcon2 from "../../Assets/Images/Home/nine-icon2.webp";
import nineIcon3 from "../../Assets/Images/Home/nine-icon3.webp";
import nineIcon4 from "../../Assets/Images/Home/nine-icon4.webp";
import nineIcon5 from "../../Assets/Images/Home/nine-icon5.webp";
import nineIcon6 from "../../Assets/Images/Home/nine-icon6.webp";
import nineIcon7 from "../../Assets/Images/Home/nine-icon7.webp";
import nineIcon8 from "../../Assets/Images/Home/nine-icon8.webp";
import nineIcon9 from "../../Assets/Images/Home/nine-icon9.webp";
import downloadaIcon from "../../Assets/Images/Home/download-icon.webp";
import downloadImg from "../../Assets/Images/Home/download-img.webp";
import recatangleIcon from "../../Assets/Images/Home/rectangle-icon.webp";
import { FacebookProvider, Page as Fbpage } from "react-facebook";
import { Tweet } from "react-twitter-widgets";
import { Document, Page, pdfjs } from "react-pdf";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import PDFViewerComp from "./PdfViewer";
import { Helmet } from "react-helmet";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDF_FILE_URL =
  "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/media/media_pdf.pdf";
function Home() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageData = useSelector((state) => state.language);
  const [isIntervalRunning, setIsIntervalRunning] = useState(true);
  const [showPhotoGallery, setShowPhotoGallery] = useState(true);
  let newObject = JSON.parse(window.localStorage.getItem("languageData"));
  useEffect(() => {
    if (newObject && newObject !== null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);
  function downloadImage(imageSrc) {
    const anchor = document.createElement("a");
    anchor.href = imageSrc;
    anchor.download = "image";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
  const downloadFileAtURL = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", "fileName");
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };
  const [index, setIndex] = useState(0);
  const slidesImg = [slide1, slide2, slide3, slide4, slide5, slide6, slide1];

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const renderPages = () => {
    const pages = [];
    for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
      pages.push(
        <div key={pageNumber} style={{ marginBottom: "20px" }}>
          <Page pageNumber={pageNumber} renderTextLayer={false} />
        </div>
      );
    }
    console.log({ pages });
    return pages;
  };

  const jurney = [
    {
      images: [, , , jurney1],
      text: t("home.jurneyText"),
      buttonText: "2017",
    },
    {
      images: [, , , jurney2],
      text: t("home.jurneyText"),
      buttonText: "2018",
    },
    {
      images: [, , , homecontentImg1],
      text: t("home.slider_text2"),
      buttonText: "2019",
    },
    {
      images: [, , , Journeyimg1],
      text: t("home.slider_text1"),
      buttonText: "2020",
    },

    {
      images: [, , , jurney3],
      text: t("home.slider_text3"),
      buttonText: "2021",
    },
    {
      images: [, , , jurney4],
      text: t("home.slider_text6"),
      buttonText: "2022",
    },
    {
      images: [, , , homecontentImg10],
      text: t("home.slider_text4"),
      buttonText: "2023",
    },
  ];

  const changeSlide = (newIndex) => {
    setIndex(newIndex);
  };

  useEffect(() => {
    const slideInterval = setInterval(() => {
      if (isIntervalRunning) {
        setIndex((prevIndex) => (prevIndex + 1) % jurney.length);
      }
    }, 3000);

    return () => {
      clearInterval(slideInterval);
    };
  }, [isIntervalRunning, jurney.length]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.twttr.widgets.load();
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleCarouselNavigation = (direction, targetIndex) => {
    if (direction === "prev") {
      setIndex((prevIndex) =>
        prevIndex === 0 ? slidesImg.length - 1 : prevIndex - 1
      );
    } else if (direction === "next") {
      setIndex((prevIndex) =>
        prevIndex === slidesImg.length - 1 ? 0 : prevIndex + 1
      );
    } else {
      setIndex(targetIndex);
    }
  };

  const handlePrev = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? jurney.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIndex((prevIndex) =>
      prevIndex === jurney.length - 1 ? 0 : prevIndex + 1
    );
  };

  const toggleSlideInterval = () => {
    setIsIntervalRunning((prevValue) => !prevValue);
  };

  const nextSlide = () => {
    setIndex((prevIndex) => (prevIndex + 1) % jurney.length);
  };

  const prevSlide = () => {
    setIndex((prevIndex) => (prevIndex - 1 + jurney.length) % jurney.length);
  };

  const navigate = useNavigate();

  const handleClickEvent = () => {
    console.log("handleClick called");
    navigate("/media-gallery/events");
  };
  const handleClickPress = () => {
    console.log("handleClick called");
    navigate("/press/news-clip");
  };

  const videoRef = useRef(null);
  const [isPaused, setIsPaused] = useState(true);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  const handleVideoPlay = () => {
    setIsPaused(false);
  };

  const handleVideoPause = () => {
    setIsPaused(true);
  };

  const handleScroll = () => {
    if (videoRef.current) {
      const videoTop = videoRef.current.getBoundingClientRect().top;
      const threshold = window.innerHeight - 1400;
      if (videoTop < threshold && !videoRef.current.paused) {
        videoRef.current.pause();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="content">
      <Helmet>
        <link rel="canonical" href="https://www.drswapnaverma.com/" />
        <title>Dr. Swapna Verma Official Website</title>
        <meta
          name="description"
          content="Dr. Swapna leads healthcare initiatives & medical education reform in India. Her innovations like Visual Medicine & Clinic on Wheels are transforming lives."
        />
        <meta
          name="keywords"
          content="Healthcare Initiatives, mental health, rural healthcare, digital medicine, Digital medicine in India, Rural healthcare initiatives, Mental health initiatives India, Disease-Free India, healthcare initiatives"
        />
      </Helmet>

      <div>
        <div
          className="video-container"
          onClick={handleVideoClick}
          onPlay={handleVideoPlay}
          onPause={handleVideoPause}
        >
          <video ref={videoRef} autoPlay loop className="video-player w-100">
            <source
              src="https://d1r7405w39vw0d.cloudfront.net/swapna-verma/homeVideos/v1.mp4"
              type="video/mp4"
            />
          </video>
        </div>

        <section>
          <div className="container mt-5">
            <h2>{t("home.homeEvent")}</h2>
            <div className="card-headlines ">
              <img src={lineIcon} className="text-center" />
            </div>
            <br />
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <div className="effected-image">
                  <img src={card9} className="college-bg-icon" />
                  <div className="simple-text">
                    <p>
                      <span>{t("home.homeEvent11")}</span>
                    </p>
                  </div>
                  <div className="overlay-sim-text overlay-xs">
                    <p>
                      <p className="collage-inside-text ">
                        {t("home.homeEvent11")}
                      </p>
                      <Link to="/media-gallery/events" className="link-a">
                        {t("home.homeEvent9")}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-4">
                <div className="effected-image">
                  <img src={card2} className="college-bg-icon" />
                  <div className="simple-text">
                    <p>
                      <span>{t("home.homeEvent12")}</span>
                    </p>
                  </div>
                  <div className="overlay-sim-text overlay-xs">
                    <p>
                      <p className="collage-inside-text ">
                        {t("home.homeEvent12")}
                      </p>
                      <Link to="/media-gallery/events" className="link-a">
                        {t("home.homeEvent9")}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-4">
                <div className="effected-image">
                  <img src={card1} className="college-bg-icon" />
                  <div className="simple-text">
                    <p>
                      <span>{t("home.homeEvent15")}</span>
                      <br />
                      <br />
                      <br />
                    </p>
                  </div>
                  <div className="overlay-sim-text overlay-xs">
                    <p>
                      <p className="collage-inside-text ">
                        {t("home.homeEvent15")}
                      </p>
                      <Link to="/media-gallery/events" className="link-a">
                        {t("home.homeEvent9")}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
        <br />
        <br />
        <section>
          <br />
          <h2>{t("home.homeEvent3")}</h2>
          <div className="card-headlines ">
            <img src={lineIcon} className="text-center" />
          </div>
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="press-section">
                  <div className="hover-text">
                    <div className="overlad">
                      <Link to="/press/news-clip" className="link-a">
                        {t("home.homeEvent9")}
                      </Link>
                    </div>
                    <img src={card7} className="college-bg-img" />
                  </div>
                  <p className="card-text">{t("home.homeEvent10")}</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="press-section">
                  <div className="hover-text">
                    <div className="overlad">
                      <Link to="/press/news-clip" className="link-a">
                        {t("home.homeEvent9")}
                      </Link>
                    </div>
                    <img src={card6} className="college-bg-img" />
                  </div>
                  <p className="card-text">{t("home.homeEvent6")}</p>
                </div>
              </div>

              <div className="col-md-4 mb-4">
                <div className="press-section">
                  <div className="hover-text">
                    <div className="overlad">
                      <Link to="/press/news-clip" className="link-a">
                        {t("home.homeEvent9")}
                      </Link>
                    </div>
                    <img src={card5} className="college-bg-img" />
                  </div>
                  <p className="card-text">{t("home.homeEvent14")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <h2>{t("home.mediaKit")}</h2>

        <div className="card-headlines ">
          <img src={lineIcon} className="text-center" />
        </div>
        <br />
        <section className="nine-icons">
          <br />
          <br />

          <div className="container">
            {!showPhotoGallery && (
              <div className="main-center-box">
                <div className="center-header-box">
                  <p>
                    {t("home.download")}
                    <img
                      src={downloadImg}
                      className="download-img"
                      onClick={() => {
                        downloadFileAtURL(PDF_FILE_URL);
                      }}
                    />
                  </p>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-lg-2 col-md-2 tabs-container">
                <div className="right-side-text-card text-center">
                  <img src={recatangleIcon} className="recatangle-icon mb-2" />
                  <span
                    onClick={() => setShowPhotoGallery(false)}
                    className="d-block mb-2"
                  >
                    {t("home.vemaProfile")}
                  </span>
                  <img src={recatangleIcon} className="recatangle-icon mb-2" />
                  <span
                    onClick={() => setShowPhotoGallery(true)}
                    className="d-block"
                  >
                    {t("home.photoGallery")}
                  </span>
                </div>
              </div>
              <div className="col-lg-10 col-md-10 mobile-view">
                {showPhotoGallery ? (
                  <div className="row content-boxes">
                    {[
                      nineIcon1,
                      nineIcon2,
                      nineIcon3,
                      nineIcon4,
                      nineIcon5,
                      nineIcon6,
                      nineIcon7,
                      nineIcon8,
                      nineIcon9,
                    ].map((icon, index) => (
                      <div key={index} className="col-md-4 col-sm-6 mb-4">
                        <img src={icon} className="img-fluid" />
                        <div className="bottom-boxcard">
                          <p className="bottom-boxcard-heading">
                            {t("home.download")}
                            <img
                              src={downloadaIcon}
                              className="download-icon"
                              onClick={() => downloadImage(icon)}
                            />
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="mt-4 pdf-box d-flex justify-content-center ">
                    <PDFViewerComp />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-5  ">
            <h2>{t("home.prosperityRamRajya")}</h2>

            <div className="card-headlines ">
              <img src={lineIcon} className="text-center" />
            </div>
            <div
              className="row justify-content-center mt-5"
              style={{ gap: "46px" }}
            >
              <div className="col-md-5 mb-4">
                <div className="new-section-left-header">
                  <p>{t("home.prosperityRamRajya1")}</p>
                </div>
                <div className="new-section-card">
                  <p>{t("home.prosperityRamRajya2")}</p>
                </div>
              </div>
              <div className="col-md-5 mb-4">
                {i18n.language !== "en" && (
                  <div className="new-section-right-header">
                    <p>संवाद से रिश्तों में सुधार</p>
                  </div>
                )}
                {i18n.language !== "hnd" && (
                  <div className="new-section-right-header-eng">
                    <p>
                      Improving Interpersonal Relationships Through Healthy
                      Communication
                    </p>
                  </div>
                )}

                <div className="new-section-right-card">
                  <p>{t("home.prosperityRamRajya4")}</p>
                </div>
              </div>
            </div>

            <div className="row justify-content-center" style={{ gap: "46px" }}>
              <div className="col-md-5 mb-4">
                <div className="new-section-right-header">
                  <p>{t("home.prosperityRamRajya5")}</p>
                </div>
                <div className="new-section-right-card">
                  <p>{t("home.prosperityRamRajya6")}</p>
                </div>
              </div>

              <div className="col-md-5 mb-4">
                {i18n.language !== "en" && (
                  <div className="new-section-left-header">
                    <p>सक्षम , समर्थ और संस्कारवान बनाती संस्कार पाठशाला</p>
                  </div>
                )}
                {i18n.language !== "hnd" && (
                  <div className="new-section-left-header-eng">
                    <p>
                      Sanskar Pathsala Enhances Cultural Values Capability And
                      Competency
                    </p>
                  </div>
                )}
                <div className="new-section-left-card">
                  <p>{t("home.prosperityRamRajya8")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <br />
        <br />
        <br />
        <section>
          <h1> {t("home.homeEvent7")}</h1>
          <div className="card-headlines ">
            <img src={lineIcon} className="text-center" />
          </div>
          <br />
          <br />
          <div className="container">
            <div className="row">
              <div className="social-section-content">
                <div className="col-4">
                  <div
                    id="twitter-timeline-section"
                    style={{
                      width: "340px",
                      height: "500px",
                    }}
                  >
                    <div className="twitt-header">
                      <p>Twitter</p>
                    </div>
                    {/* <Tweet tweetId="1737736289569722505"> </Tweet> */}
                    <a
                      className="twitter-grid"
                      href="https://twitter.com/Dr_SwapnaVerma?ref_src=twsrc%5Etfw"
                    ></a>
                  </div>
                </div>
                <div className="col-4">
                  <div id="fb-timeline-section">
                    <div className="Facebook-header">
                      <p>Facebook</p>
                    </div>
                    <FacebookProvider appId="your-facebook-app-id">
                      <Fbpage
                        href="https://www.facebook.com/Drswapna.vermaa"
                        tabs="timeline"
                      />
                    </FacebookProvider>
                  </div>
                </div>
                <div className="col-4">
                  <div id="youtube-video-section">
                    <div className="Youtube-header">
                      <p>Youtube</p>
                    </div>
                    <iframe
                      src="https://www.youtube.com/embed/ka6nRzpqD30"
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                      title="video"
                      height={500}
                      width={350}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
        <br />
        <br />
        <section>
          <h2> {t("home.homeEvent8")}</h2>
          <div className="card-headlines">
            <img src={lineIcon} className="text-center" alt="Line Icon" />
          </div>
          <br />
          <br />
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div>
                  <input
                    type="text"
                    id="fname"
                    name="firstname"
                    placeholder={t("contact.contact_text3")}
                  />
                  <input
                    type="text"
                    id="lname"
                    name="lastname"
                    placeholder={t("contact.contact_text4")}
                  />
                  <input
                    type="text"
                    id="lname"
                    name="lastname"
                    placeholder={t("contact.contact_text5")}
                  />
                  <textarea
                    type="text"
                    id="lname"
                    name="lastname"
                    placeholder={t("contact.contact_text6")}
                  />
                  <button className="submit-btn">
                    {t("contact.contact_text1")}
                  </button>
                </div>
              </div>
              <div className="col-md-6 button mt-5">
                {i18n.language !== "en" && (
                  <img src={contactLable} className="w-100" />
                )}
                {i18n.language !== "hnd" && (
                  <img src={engLable} className="w-100" />
                )}
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
      </div>
    </div>
  );
}
export default Home;
