import { LANGUAGE } from "../Action/Types";

const initialState = {
    language: '',
}

const appReducer = (state, action) => {
    switch (action.type) {
        case LANGUAGE:
            return { ...state, language: action.payload };
        default:
            return initialState;
    }
}

export default appReducer;