import React, { useEffect } from "react";
import "./Glimpse.scss";
import Header from "../../Components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MyVoiceHeader from "../../Components/Header/MyVoiceHeader";
import gallerImg1 from "../../Assets/Images/gallery/gallery-img-1.webp";
import gallerImg2 from "../../Assets/Images/gallery/gallery-img-2.webp";
import gallerImg3 from "../../Assets/Images/gallery/gallery-img-3.webp";
import gallerImg4 from "../../Assets/Images/gallery/gallery-img-4.webp";
import gallerImg6 from "../../Assets/Images/gallery/gallery-img-6.webp";
import gallerImg8 from "../../Assets/Images/gallery/gallery-img-8.webp";
import gallerImg9 from "../../Assets/Images/gallery/gallery-img-9.webp";
import { Helmet } from "react-helmet";
const galleryItems = [
  {
    id: 1,
    image: gallerImg1,
    videoLink:
      "https://drive.google.com/file/d/1OfKUEirH2OoPPuypsNOpMv0xPe7EnY7b/view",
    text: "gallery.gallery_card1",
    location: "gallery.location",
    subDate: "gallery.gallerDte",
  },
  {
    id: 2,
    image: gallerImg2,
    videoLink: "https://www.youtube.com/watch?v=kQojgrNmbZg",
    text: "gallery.gallery_card4",
    location: "gallery.location1",
    subDate: "gallery.gallerDte1",
  },
  {
    id: 3,
    image: gallerImg3,
    videoLink: "https://www.youtube.com/watch?v=J2OI81CBGxw",
    text: "gallery.gallery_card7",
    location: "gallery.location2",
    subDate: "gallery.gallerDte2",
  },
  {
    id: 4,
    image: gallerImg4,
    videoLink: "https://www.youtube.com/watch?v=FKnQgWyUcw0",
    text: "gallery.gallery_card10",
    location: "gallery.location3",
    subDate: "gallery.gallerDte3",
  },
  // {
  //   id: 5,
  //   image: gallerImg5,
  //   videoLink: "https://www.youtube.com/watch?v=Eknu0toSdrU&feature=youtu.be",
  //   text: "gallery.gallery_card12",
  //   location: "gallery.location4",
  //   subDate: "gallery.gallerDte4",
  // },
  {
    id: 6,
    image: gallerImg6,
    videoLink: "https://www.youtube.com/watch?v=LytnRlZDzsY&feature=youtu.be",
    text: "gallery.gallery_card14",
    location: "gallery.location5",
    subDate: "gallery.gallerDte5",
  },
  // {
  //   id: 7,
  //   image: gallerImg7,
  //   videoLink: "https://www.youtube.com/watch?v=uwVbVPm5Ojs&feature=youtu.be",
  //   text: "gallery.gallery_card16",
  //   location: "gallery.location6",
  //   subDate: "gallery.gallerDte6",
  // },
  {
    id: 8,
    image: gallerImg8,
    videoLink:
      "https://drive.google.com/file/d/1NsWoYvWLZadpUBDmwmnHnudyL6dPMePN/view",
    text: "gallery.gallery_card19",
    location: "gallery.location7",
    subDate: "gallery.gallerDte7",
  },
  {
    id: 9,
    image: gallerImg9,
    videoLink:
      "https://drive.google.com/file/d/1bSgdVU2umayiPi5qQWUhZ_obkRu87Hsm/view",
    text: "gallery.gallery_card21",
    location: "gallery.location8",
    subDate: "gallery.gallerDte9",
  },
];

function Glimpse() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageData = useSelector((state) => state.language);
  let newObject = JSON.parse(window.localStorage.getItem("languageData"));
  useEffect(() => {
    if (newObject && newObject != null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);

  const translatedText = t("gallery.gallery_text");

  return (
    <div className="content-myvoice">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.drswapnaverma.com/media-gallery/a-glimpse"
        />
        <title>
          Healthy Villages, Healthy India: Rural Healthcare Initiatives
        </title>
        <meta
          name="description"
          content="Empowering rural communities! Explore free health insurance, eye care camps, mobile clinics & more initiatives for a disease-free rural India."
        />
        <meta
          name="keywords"
          content="Disease Free Rural India, Free Health Insurance, Rural Healthcare Initiatives, Mobile Clinics, Sadguru Eye Clinic, eye checkup camps, Community Health Programs, Health Awareness"
        />
      </Helmet>

      <div>
        <div className="gallery">
          <br />
          <br />
          <Header title={translatedText} />
          <br />
          <br />
          <br />
          <MyVoiceHeader />

          <section className="container myvoice-main-container">
            <div className="container mt-5">
              <div className="row     justify-content-center">
                {galleryItems.map((item) => (
                  <div className="col-md-4   mt-5" key={item.id}>
                    <div className="my-voice-section-card">
                      <a
                        href={item.videoLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={item.image} alt="Card image cap" />
                      </a>
                      <div className="">
                        <br />
                        <h5 className="card-head">{t(item.text)}</h5>
                      </div>
                    </div>
                    <div className="sub-text-voice">
                      <p>{t(item.location)}</p>
                      <p>{t(item.subDate)}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
          </section>
        </div>
      </div>
    </div>
  );
}

export default Glimpse;
