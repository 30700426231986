import React, { useEffect, useState, useRef } from "react";
import "./Events.scss";
import Header from "../../Components/Header/Header";
import { readService } from "../../Services/HttpService";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-bootstrap";
import backIocn from "../../Assets/Images/events/back-icon.svg";
import ReactPlayer from "react-player";
import readMoreIocn from "../../Assets/Images/events/read-more-icon.svg";
import Loader from "../../Components/loaderScreen/loaderScreen";
import { Helmet } from "react-helmet";

function Events() {
  const [showCarousel, setShowCarousel] = useState(false);
  const [loading, setLoading] = useState(true);
  const languageData = useSelector((state) => state.language);
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [sections, setSections] = useState([]);
  const [eventListById, setEventIdById] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [selectedImgIndex, setSelectedImgIndex] = useState(0);
  const [mainScreenVisible, setMainScreenVisible] = useState(true);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let newObject = JSON.parse(window.localStorage.getItem("languageData"));

  const scrollPositionRef = useRef(0);

  useEffect(() => {
    if (newObject && newObject !== null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);
  console.log("new", newObject.language);

  useEffect(() => {
    getEventDataList();
  }, []);

  useEffect(() => {
    console.log(languageData);
  }, [languageData]);

  const togglePopup = (index, event, eventId) => {
    scrollPositionRef.current = window.scrollY;
    setSelectedEvent(event);
    setVisiblePopup(true);
    getEventListFromEventId(eventId);
    setClickedImageIndex(index);
  };

  const getEventDataList = async () => {
    try {
      setLoading(true);
      let response = await readService("swapna/event");
      if (response) {
        setSections(response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const getEventListFromEventId = async (eventId) => {
    let params = {
      eventId: eventId,
    };
    let response = await readService("swapna/event", params);
    if (response) {
      setEventIdById(response.data);
    }
  };

  const navigateBack = () => {
    setVisiblePopup(false);
    setEventIdById("");
    window.scrollTo(0, scrollPositionRef.current);
  };
  useEffect(() => {
    if (!visiblePopup && clickedImageIndex !== null) {
      const imageElement = document.getElementById(
        `event-image-${clickedImageIndex}`
      );
      if (imageElement) {
        const elementPosition = imageElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - 160;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [visiblePopup, clickedImageIndex]);

  const translatedText = t("event.event_text");

  const openCarousel = (imgIndex) => {
    setSelectedImgIndex(imgIndex);
    setShowCarousel(true);
    setMainScreenVisible(false);
  };

  const closeCarousel = () => {
    setShowCarousel(false);
    setMainScreenVisible(true);
  };

  const navigateBackpage = () => {
    setShowCarousel(false);
    setMainScreenVisible(true);
  };

  const handleSelect = (selectedIndex, e) => {
    setSelectedImgIndex(selectedIndex);
  };

  const handleCoverImageClick = () => {
    setIsVideoPlaying(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex =
        selectedImgIndex === eventListById.length - 1
          ? 0
          : selectedImgIndex + 1;
      setSelectedImgIndex(newIndex);
    }, 3000);

    return () => clearInterval(interval);
  }, [selectedImgIndex, eventListById]);

  return (
    <div className="content-event">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.drswapnaverma.com/media-gallery/events"
        />
        <title>
          Dr. Swapna Verma | Satna | Healthcare, Social Work & Education
        </title>
        <meta
          name="description"
          content="Dr. Swapna Verma: BJP leader, healthcare professional & social worker in Satna. Committed to public service, women's empowerment & education. Learn more about her work."
        />
        <meta
          name="keywords"
          content="Women Empowerment, Mental Health, Youth Development, Gram Panchayat, Public service, rural development"
        />
      </Helmet>

      {loading && <Loader />}
      <div>
        <div className="event">
          <br />
          <br />
          <Header title={translatedText} />
        </div>
        <br />
        <br />
        <br />
        <br />

        <div className="event-bg">
          <br />
          <br />
          <div className="container section-body">
            <div className="row d-flex justify-content-center">
              {sections &&
                sections.map((section, index) => (
                  <div key={index} className="col-md-4">
                    {!visiblePopup && (
                      <div>
                        <a
                          onClick={() =>
                            togglePopup(index, section, section.eventId)
                          }
                        >
                          <img
                            id={`event-image-${index}`}
                            src={section.url}
                            alt={`Event ${index + 1}`}
                            className="event-section-img"
                          />
                        </a>
                        <div className="d-flex justify-content-between  mb-3 mt-4">
                          <div>
                            <p className="evnet-sub-text">
                              {section &&
                                (languageData?.language === "hnd"
                                  ? section?.hindiTitle
                                  : section?.engTitle)}
                            </p>
                          </div>
                          {/* <div>
                            <a
                              className="red-more"
                              onClick={() =>
                                togglePopup(index, section, section.eventId)
                              }>
                              <img src={readMoreIocn} />
                              {t("event.readmore")}
                            </a>
                          </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
          {visiblePopup && (
            <div className="popup">
              <br />
              <section className="container"></section>
              <div className="container">
                {mainScreenVisible && (
                  <div
                    className="row"
                    style={{ width: "100%", justifyContent: "center" }}
                  >
                    <h2 className="event-main-text">
                      {selectedEvent &&
                        (languageData?.language === "hnd"
                          ? selectedEvent?.hindiTitle
                          : selectedEvent?.engTitle)}
                    </h2>
                    <h2 className="event-sub-text">{selectedEvent?.date}</h2>
                    <p className="event-sub-text-p">
                      {selectedEvent &&
                        (languageData?.language === "hnd"
                          ? selectedEvent?.hindiDescription
                          : selectedEvent?.engDescription)}
                    </p>
                    <h2 className="event-sub-text-p">
                      {selectedEvent &&
                        (languageData?.language === "hnd"
                          ? selectedEvent?.hindiDate
                          : selectedEvent?.engDate)}
                    </h2>

                    <div className="event-image-section">
                      <span onClick={navigateBack}>
                        <img
                          src={backIocn}
                          className="text-end back-btn mx-4 "
                          style={{ marginTop: "-4px" }}
                        />
                        <span className="span-back-newpage text-end back-btn">
                          {" "}
                          {t("event.backButton")}
                        </span>
                      </span>
                      <div className="container">
                        {eventListById && (
                          <div
                            className="row"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {eventListById.map((item, imgIndex) => (
                              <div key={imgIndex} className="col-md-4">
                                <a onClick={() => openCarousel(imgIndex)}>
                                  <img
                                    src={item.url || item.videoCoverImg}
                                    alt={imgIndex}
                                    className="event-section-img1"
                                    style={{
                                      // margin: "5 2px",
                                      width: "calc(100% - -10px)",
                                    }}
                                  />
                                </a>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {showCarousel && (
            <div className="carouselId">
              <div className="container">
                <div className="col-md-12  text-content-Carousel">
                  <h2 className=" Carousel-event-main-text">
                    {selectedEvent &&
                      (languageData?.language === "hnd"
                        ? selectedEvent?.hindiTitle
                        : selectedEvent?.engTitle)}
                  </h2>
                  <h2 className="event-sub-text">{selectedEvent?.date}</h2>
                  <p className="Carousel-event-sub-text-p">
                    {selectedEvent &&
                      (languageData?.language === "hnd"
                        ? selectedEvent?.hindiDescription
                        : selectedEvent?.engDescription)}
                  </p>
                  <h2 className="Carousel-event-sub-text-p">
                    {selectedEvent &&
                      (languageData?.language === "hnd"
                        ? selectedEvent?.hindiDate
                        : selectedEvent?.engDate)}
                  </h2>
                </div>
              </div>
              <div
                className="container d-flex justify-content-center"
                id="carousel-section"
              >
                <span className="back-sapn" onClick={navigateBackpage}>
                  <img src={backIocn} className="back-sapn-icon" />
                  {t("event.backButton")}
                </span>
                <Carousel
                  className="event-mode"
                  activeIndex={selectedImgIndex}
                  onSelect={handleSelect}
                  interval={null}
                >
                  {eventListById.map((item, imgIndex) => (
                    <Carousel.Item key={imgIndex}>
                      <div>
                        {item.url ? (
                          <img
                            src={item.url}
                            alt={`Image ${imgIndex}`}
                            className="d-block w-100 event-mode"
                          />
                        ) : null}
                        {item.videoLink ? (
                          <div>
                            {isVideoPlaying ? null : (
                              <img
                                src={item.videoCoverImg}
                                alt={`Video Cover ${imgIndex}`}
                                className="d-block w-100 event-mode"
                                onClick={handleCoverImageClick}
                              />
                            )}
                            {isVideoPlaying ? (
                              <ReactPlayer
                                url={item.videoLink}
                                playing={true}
                                onPause={() => setIsVideoPlaying(false)}
                                width="800px"
                              />
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
              <br />
              <br />
              <br />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Events;
