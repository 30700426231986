import React from "react";
import "./Initiatives.scss";
import Womenimg from "../../Assets/Images/initiative/WomenEmpowerment.webp";
import backIcon from "../../Assets/Images/initiative/back-icon.svg";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InitiativesHeader from "./InitiativesHeader";
import { Helmet } from "react-helmet";
import SubHeader from "../../Components/Header/SubHeader";
export default function WomenEmpowerment() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageData = useSelector((state) => state.language);
  let newObject = JSON.parse(window.localStorage.getItem("languageData"));
  useEffect(() => {
    if (newObject && newObject != null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);

  const translatedText = t("initiatives.initiatives_text");
  const navigate = useNavigate();
  const navigatePage = () => {
    navigate("/initiatives");
  };
  const backBtn = t("event.backButton");
  const maintaxt = t("WomenEmpowerment.womenEmpmt");
  return (
    <div className="content-initiative">
      <Helmet>
        <link rel="canonical" href="https://www.drswapnaverma.com/women" />
        <title>Dr. Swapna Verma's Women Empowerment Program</title>
        <meta
          name="description"
          content="Dr. Swapna Verma empowers women! Develop skills, gain confidence, & achieve dreams. Equality for women in India. Personal & social development programs."
        />
        <meta
          name="keywords"
          content="Women Empowerment Program, skill development, confidence building, personal development, personal development for women, Women Empowerment, Women's leadership"
        />
      </Helmet>
      <br />
      <br />
      <SubHeader title={translatedText} />
      <InitiativesHeader backButton={backBtn} titleName={maintaxt} />
      <div className="bg-all-Initiatives">
        <br />
        <br />

        <div className="container custom-container mt-4 text-center">
          <div className="row">
            <h3 className="main-title">{t("WomenEmpowerment.womenEmpmt6")}</h3>
            <br />
            <br />
            <div>
              <a
                href="https://www.youtube.com/watch?v=iqNJmikn8as&feature=youtu.be"
                target="_blank"
              >
                <img src={Womenimg} className="img-fluid main-header-img" />
              </a>
            </div>
            <div>
              <div>
                <div className="justify-content">
                  <br />
                  <span className="">{t("WomenEmpowerment.womenEmpmt1")}</span>
                  <span className="">{t("WomenEmpowerment.womenEmpmt2")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div>
              <div className="justify-content">
                <span>
                  <span>
                    {t("WomenEmpowerment.womenEmpmt3")}
                    <br />
                    <br />
                    {t("WomenEmpowerment.womenEmpmt4")}
                    <br />
                    {t("WomenEmpowerment.womenEmpmt5")}
                  </span>
                </span>
              </div>
            </div>
            <div className="col-md-1 col-sm-12"></div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}
