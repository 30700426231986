import React from "react";
import "./MyVoice.scss";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import myvoice2 from "../../Assets/Images/voice/My-Voice-2.webp";
import myvoice4 from "../../Assets/Images/voice/My-Voice-4.webp";
import myvoice56 from "../../Assets/Images/voice/My-Voice-56.png";
import myvoice7 from "../../Assets/Images/voice/My-Voice-7.webp";
import myvoice8 from "../../Assets/Images/voice/My-Voice-8.webp";
import myvoice9 from "../../Assets/Images/voice/My-Voice-9.webp";
import myvoice10 from "../../Assets/Images/voice/My-Voice-10.webp";
import myvoice11 from "../../Assets/Images/voice/My-Voice-11.webp";
import myvoice12 from "../../Assets/Images/voice/My-Voice-12.webp";
import myvoice13 from "../../Assets/Images/voice/My-Voice-13.webp";
import myvoice14 from "../../Assets/Images/voice/My-Voice-14.webp";
import myvoice15 from "../../Assets/Images/voice/My-Voice-15.webp";
import myvoice16 from "../../Assets/Images/voice/My-Voice-16.png";
import myvoice17 from "../../Assets/Images/voice/My-Voice-17.webp";
import myvoice18 from "../../Assets/Images/voice/My-Voice-18.png";
import Header from "../../Components/Header/Header";
import MyVoiceHeader from "../../Components/Header/MyVoiceHeader";
import { Helmet } from "react-helmet";

const MyVoice = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageData = useSelector((state) => state.language);
  let newObject = JSON.parse(window.localStorage.getItem("languageData"));

  useEffect(() => {
    if (newObject && newObject !== null) {
      i18n.changeLanguage(newObject.language);
    }
  }, []);

  const myvoiceData = [
    {
      imageUrl: myvoice18,
      videoUrl:
        "https://drive.google.com/file/d/10MuDaA7RFiTNjVemrJl0GdTrtylGSf3R/view",
      cardTitleKey: "MyVoice.myVoice_card18",
      location: "MyVoice.location14",
      date: "MyVoice.date18",
    },
    {
      imageUrl: myvoice18,
      videoUrl:
        "https://drive.google.com/file/d/15O2gAJXivAGs3ig58TLqxkWGxLWkJS__/view",
      cardTitleKey: "MyVoice.myVoice_card19",
      location: "MyVoice.location15",
      date: "MyVoice.date19",
    },
    {
      imageUrl: myvoice18,
      videoUrl:
        "https://drive.google.com/file/d/1ysBsT-Y2IsJVIcEuHRIsEHkCtbyZTEeM/view",
      cardTitleKey: "MyVoice.myVoice_card20",
      location: "MyVoice.location16",
      date: "MyVoice.date20",
    },
    {
      imageUrl: myvoice15,
      videoUrl:
        "https://drive.google.com/file/d/1zxrHIqX_lIoPj5AG-TPVFqPEMwNjNec7/view?pli=1",
      cardTitleKey: "MyVoice.myVoice_card15",
      location: "MyVoice.location10",
      date: "MyVoice.date15",
    },
    {
      imageUrl: myvoice16,
      videoUrl:
        "https://drive.google.com/file/d/1kmaySGmklhpwEIYDG6NQSyl4_vd_Uilx/view",
      cardTitleKey: "MyVoice.myVoice_card16",
      location: "MyVoice.location12",
      date: "MyVoice.date16",
    },
    {
      imageUrl: myvoice17,
      videoUrl:
        "https://www.facebook.com/Drswapna.vermaa/videos/260906990357893/",
      cardTitleKey: "MyVoice.myVoice_card17",
      location: "MyVoice.location11",
      date: "MyVoice.date17",
    },
    {
      imageUrl: myvoice14,
      videoUrl: "https://www.youtube.com/watch?v=TCxig4hwDes&feature=youtu.be",
      cardTitleKey: "MyVoice.myVoice_card14",
      location: "MyVoice.location",
      date: "MyVoice.date",
    },
    {
      imageUrl: myvoice11,
      videoUrl: "https://www.youtube.com/watch?v=7M8Q0N8mBls&feature=youtu.be",
      cardTitleKey: "MyVoice.myVoice_card11",
      location: "MyVoice.location1",
      date: "MyVoice.date1",
    },
    {
      imageUrl: myvoice12,
      videoUrl: "https://www.youtube.com/shorts/nzt4VV8phdg?feature=share",
      cardTitleKey: "MyVoice.myVoice_card12",
      location: "MyVoice.location2",
      date: "MyVoice.date2",
    },
    {
      imageUrl: myvoice13,
      videoUrl: "https://www.youtube.com/watch?v=7FtDMbrhzww&feature=youtu.be",
      cardTitleKey: "MyVoice.myVoice_card13",
      location: "MyVoice.location3",
      date: "MyVoice.date3",
    },

    {
      imageUrl: myvoice10,
      videoUrl: "https://www.youtube.com/watch?v=veame3lX8c4",
      cardTitleKey: "MyVoice.myVoice_card10",
      location: "MyVoice.location4",
      date: "MyVoice.date4",
    },
    {
      imageUrl: myvoice9,
      videoUrl: "https://www.youtube.com/watch?v=C4vMoAH6rrE",
      cardTitleKey: "MyVoice.event_card1",
      location: "MyVoice.location5",
      date: "MyVoice.date5",
    },
    // {
    //   imageUrl: myvoice1,
    //   videoUrl: "https://www.youtube.com/watch?v=lUQ33yXiOhM&feature=youtu.be",
    //   cardTitleKey: "MyVoice.event_card2",
    //   location: "MyVoice.location6",
    //   date: "MyVoice.date6",
    // },
    // {
    //   imageUrl: myvoice7,
    //   videoUrl: "https://www.youtube.com/watch?v=pdFvSF111PY&feature=youtu.be",
    //   cardTitleKey: "MyVoice.event_card8_1",
    //   location: "MyVoice.location12",
    //   date: "MyVoice.date12",
    // },
    {
      imageUrl: myvoice2,
      videoUrl:
        "https://drive.google.com/file/d/1mkytiv1dLIGylzKOIwphRxie5agtW7nZ/view",
      cardTitleKey: "MyVoice.event_card3",
      location: "MyVoice.location7",
      date: "MyVoice.date7",
    },
    {
      imageUrl: myvoice4,
      videoUrl:
        "https://drive.google.com/file/d/1UBKHcJtoCohetsU3mAz7MNCRat_mtDi5/view",
      cardTitleKey: "MyVoice.event_card5",
      location: "MyVoice.location9",
      date: "MyVoice.date9",
    },
    {
      imageUrl: myvoice56,
      videoUrl: "https://www.youtube.com/watch?v=ba5MeaG5-SU&feature=youtu.be",
      cardTitleKey: "MyVoice.event_card6",
      location: "MyVoice.location10",
      date: "MyVoice.date10",
    },
    {
      imageUrl: myvoice56,
      videoUrl: "https://www.youtube.com/watch?v=ba5MeaG5-SU&feature=youtu.be",
      cardTitleKey: "MyVoice.event_card7",
      location: "MyVoice.location11",
      date: "MyVoice.date11",
    },
    // {
    //   imageUrl: myvoice8,
    //   videoUrl: "https://www.youtube.com/watch?v=9xWldXQE8hc&feature=youtu.be",
    //   cardTitleKey: "MyVoice.myVoice_card9",
    //   location: "MyVoice.location13",
    //   date: "MyVoice.date13",
    // },
  ];

  return (
    <div className="content-myvoice">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.drswapnaverma.com/media-gallery/my-voice"
        />
        <title>Dr. Swapna Verma: Serving Satna | Healthcare, Youth</title>
        <meta
          name="description"
          content="Explore Dr. Swapna Verma's work in Satna. See initiatives like Ayushman Bharat, Khelo India & free medical camps. Committed to serving & empowering communities."
        />
        <meta
          name="keywords"
          content="free medical camps, Healthcare initiatives, Women empowerment, Youth development, Public service, Free Eye Checkup Camp, Rural Development in Satna"
        />
      </Helmet>

      <section>
        <br />
        <br />
        <Header title={t("myvoice.myvoice_hedline")} />

        <br />
        <br />
        <br />

        <MyVoiceHeader />

        <div className="container myvoice-main-container">
          <div className="container mt-5">
            <div className="row">
              {myvoiceData.map((dataItem) => (
                <div className="col-md-4   mt-5" key={dataItem.id}>
                  <div className="my-voice-section-card">
                    <a
                      href={dataItem.videoUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        // className="card-img-top rounded-0 img-fluid"
                        src={dataItem.imageUrl}
                        alt="Card image cap"
                      />
                    </a>
                    <div className="">
                      <br />
                      <h5 className="card-head">{t(dataItem.cardTitleKey)}</h5>
                    </div>
                  </div>
                  <div className="sub-text-voice">
                    <p>{t(dataItem.location)}</p>
                    <p>{t(dataItem.date)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <br />
        <br />
      </section>
    </div>
  );
};

export default MyVoice;
